import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Toggle, Select, Input } from 'FormElements';
import ReactSelect from 'react-select';
import useUser from 'ReduxHooks/useUser';
import { AdminOnly, If } from 'Conditionals';
import { useTranslation } from 'react-i18next';
import { Notice } from 'Layout';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import UserProfileRoleTabPanel from './UserProfileRoleTabPanel';

const UserProfileTab = ({ country, sites }) => {
  if (!country || !sites) {
    return null;
  }

  const { t } = useTranslation();
  const { user, updateField } = useUser();
  const countryName = country.name;
  const roles = user ? user.roles : [];
  const userProfiles = user ? user.user_profiles : [];
  const showInheritOptions = sites.length > 1;
  const [site, setSite] = useState(getSite());
  const [userProfile, setUserProfile] = useState(userProfiles?.length === 0 && userProfiles.find(profile => profile.siteId === site.id));

  if (!user || userProfiles?.length === 0) {
    return (
      <Notice type='warning'>
        <h4>{t('No profile found.')}</h4>
        <p>{t('To create a profile you need at least one country added as an active market and one active role. After a market and role has been chosen the user has to be saved.')}</p>
      </Notice>
    );
  }

  useEffect(() => {
    if (site && userProfiles.length > 0) {
      const profile = userProfiles.find(profile => profile.siteId === site.id);

      if (profile) {
        setUserProfile(profile);
      }
    }
  }, [site, userProfiles]);

  if (!userProfile) {
    return (
      <p>{t('No profile found for this market')}</p>
    );
  }

  function setMinimumNoticePeriod (value) {
    if (value === '') {
      value = null;
    }

    updateField('minimum_notice_period', value);
  }

  function getSelectedSites () {
    return userProfiles.flatMap(profile => profile.children).filter(profile => !!profile).map(profile => profile.siteId);
  }

  function getSelectedSitesWithParent () {
    return [...new Set([
      ...getSelectedSites(),
      ...userProfiles.flatMap(profile => profile.parent).filter(profile => !!profile).map(profile => profile.siteId)
    ])];
  }

  function getSiteOptions () {
    return sites.filter(site => !getSelectedSites().includes(site.id)).reduce((acc, site) => ({ ...acc, [site.id]: site.name }), {});
  }

  function getSite () {
    const sitesOptions = getSiteOptions();

    if (Object.keys(sitesOptions).length > 0) {
      return sites.find(site => site.id === Number(Object.keys(sitesOptions)[0]));
    }

    return sites[0];
  }

  // Get all sites from user profiles that already selected to inherit a profile and the parent profiles
  const selectedSitesWithParent = showInheritOptions ? getSelectedSitesWithParent() : [];

  const userProfileChildrenSites = showInheritOptions
    ? sites.filter(site =>
      (userProfile?.children && userProfile.children.map(child => child.siteId).indexOf(site.id) !== -1)
    ).map(site => ({ value: site.id, label: site.name }))
    : [];

  const userProfileChildrenSitesOptions = showInheritOptions
    ? sites.filter(site =>
      site.id !== userProfile.siteId &&
    !selectedSitesWithParent.includes(site.id)
    ).map(site => ({ value: site.id, label: site.name }))
    : [];

  const updateUserProfileChildren = (sites) => {
    const newChildren = [];

    if (sites?.length > 0) {
      sites.forEach(site => {
        userProfiles.forEach(profile => {
          if (profile.siteId === site.value) {
            newChildren.push(profile);
          }
        });
      });
    }

    updateUserProfileFields('children', newChildren);
  };

  const updateUserProfileFields = (key, value, profileId) => {
    updateField('user_profiles', userProfiles.map(profile => {
      if (profile.siteId === site.id) {
        return { ...profile, [key]: value };
      }

      return profile;
    }));
  };

  function userCanActivateProfile () {
    if (!user.profile_image) {
      return false;
    }

    if (!user.rating) {
      return true;
    }

    return user.rating >= 3;
  }

  const getLanguages = (code) => {
    switch (code) {
      case 'dk':
        return [
          'Arabisk',
          'Bulgarsk',
          'Dansk',
          'Dari',
          'Engelsk',
          'Estisk',
          'Finsk',
          'Fransk',
          'Græsk',
          'Italiensk',
          'Kroatisk',
          'Hollandsk',
          'Norsk',
          'Persisk',
          'Polsk',
          'Portugisisk',
          'Rumænsk',
          'Russisk',
          'Serbisk',
          'Spansk',
          'Svensk',
          'Tysk'
        ];
      case 'se':
        return [
          'Arabiska',
          'Bulgariska',
          'Danska',
          'Dari',
          'Engelska',
          'Estniska',
          'Finska',
          'Franska',
          'Grekiska',
          'Italienska',
          'Kroatiska',
          'Nederländska',
          'Norska',
          'Persiska',
          'Polska',
          'Portugisiska',
          'Rumänska',
          'Ryska',
          'Serbiska',
          'Spanska',
          'Svenska',
          'Tyska'
        ];
      default:
        return [
          'Arabic',
          'Bulgarian',
          'Danish',
          'Dari',
          'English',
          'Estonian',
          'Finnish',
          'French',
          'Greek',
          'Italian',
          'Croatian',
          'Dutch',
          'Norwegian',
          'Persian',
          'Polish',
          'Portuguese',
          'Romanian',
          'Russian',
          'Serbian',
          'Spanish',
          'Swedish',
          'German'
        ];
    }
  };

  const rolesAndLabels = {
    funeral_advisor: t('Funeral'),
    legal_advisor: t('Law'),
    tombstone_advisor: t('Tombstone'),
    officiant_user: t('Officiant'),
    therapy_user: t('Therapy')
  };

  return (
    <>
      <Tabs>
        <TabList
          className='react-tabs__tab-list react-tabs__sub-tab-list'
        >
          <Tab className='react-tabs__tab'>{t('General')}</Tab>
          {roles.filter(role => Object.keys(rolesAndLabels).includes(role)).map(role => (
            <Tab className='react-tabs__tab' key={role}>{rolesAndLabels[role]}</Tab>
          ))}
        </TabList>
        <If condition={sites.length > 1}>
          <div className='lav-notice lav-notice_type-notice'>
            <div className='lav-notice__container'>
              <div className='lav-notice__content'>
                <h4>{t('Handle your profiles for') + ' ' + countryName}</h4>
                <div className='row'>
                  <div className='col-8'>
                    <div className='react-select__floating-label input'>
                      <Select
                        label={t('Select a site')}
                        value={site.id}
                        options={getSiteOptions()}
                        onChange={value => setSite(sites.find(site => Number(site.id) === Number(value)))}
                        className='lavendla-select location-dropdown'
                        classNamePrefix='lavendla-select'
                        floatingLabel
                      />
                    </div>
                    <br />
                    <p>{t('Select other sites that will inherit this sites profile.')}</p>
                    <div className='react-select__floating-label floating-label input has-content'>
                      <ReactSelect
                        value={userProfileChildrenSites}
                        options={userProfileChildrenSitesOptions}
                        className='lavendla-select'
                        classNamePrefix='lavendla-select'
                        onChange={updateUserProfileChildren}
                        placeholder={t('-- Select sites --')}
                        isDisabled={userProfileChildrenSites.length === 0 && userProfileChildrenSitesOptions.length === 0}
                        isMulti
                      />
                      <label htmlFor='location'>{t('Sites inheriting this profile')}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </If>
        <TabPanel>
          <div className='row new-form'>
            <div className='col-8'>
              <AdminOnly>
                <Toggle
                  isDisabled={!userCanActivateProfile()}
                  defaultChecked={userProfile.active}
                  label={t('Activate your profile page on the web')}
                  onChange={() => updateUserProfileFields('active', !userProfile.active)}
                />
              </AdminOnly>

              <If condition={!userCanActivateProfile()}>
                <Notice type='warning' classlist='user-profile-toggle-warning'>
                  <h4>{t('Unable to activate profile')}</h4>
                  <p>{t('To activate your profile, you will need to contact the support team. If you are new at Lavendla and missing a profile picture, you need to submit a profile picture to the support team through the chat. You will also need to have information filled in on your profile.')}</p>
                </Notice>
              </If>

              <If condition={userProfile.active && userProfile.url}>
                <p>
                  {t('Your profile can be found on')}<br />
                  <a target='_blank' rel='noopener noreferrer' href={userProfile.url}>
                    {userProfile.url}
                  </a>
                </p>
                <br />
              </If>

              <div className='react-select__floating-label input'>
                <ReactSelect
                  defaultValue={userProfile.languages ? userProfile.languages.map(language => ({ value: language, label: language })) : []}
                  options={getLanguages(userProfile.countryCode).map(language => ({ value: language, label: language }))}
                  className='lavendla-select'
                  classNamePrefix='lavendla-select'
                  onChange={value => updateUserProfileFields('languages', value.map(item => item.value))}
                  placeholder={t('-- Select languages --')}
                  isMulti
                />
                <label htmlFor='language'>{t('Languages')}</label>
              </div>
              <Input
                type='number'
                label={t('Minimum notice period')}
                value={user.minimum_notice_period}
                min={0}
                onChange={value => setMinimumNoticePeriod(value)}
                floatingLabel
              />
            </div>
          </div>
        </TabPanel>
        {roles.filter(role => Object.keys(rolesAndLabels).includes(role)).map(role => (
          <TabPanel
            key={role}
          >
            <UserProfileRoleTabPanel role={role} country={country} userProfile={userProfile} updateUserProfileFields={updateUserProfileFields} />
          </TabPanel>
        ))}
      </Tabs>
    </>
  );
};

UserProfileTab.propTypes = {
  country: PropTypes.object,
  sites: PropTypes.array
};

export default UserProfileTab;
