import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'Library/functions';
import UserImage from './UserImage';

const Event = ({ event, countryCode }) => {
  return (
    <li className='single-event'>
      <div className='single-event__profile-image'>
        {event.user ? <UserImage user={event.user} /> : <img src='/icons/lavendla-symbol.svg' class='single-event__system-image' />}
      </div>
      <div className='single-event__data'>
        <time>{formatDate(event.created_at, countryCode, 'P pp')}</time>
        <div className='event-action-text' dangerouslySetInnerHTML={{ __html: event.action }} />
      </div>
    </li>
  );
};

Event.propTypes = {
  event: PropTypes.object.isRequired
};

export default Event;
