import React from 'react';
import UserDropdown from '../UserDropdown';
import UserCountryDropdown from '../UserCountryDropdown';
import PropTypes from 'prop-types';
import { AdminOnly } from 'Conditionals';
import { useTranslation } from 'react-i18next';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';

const Toolbar = ({
  country,
  label,
  onNavigate,
  onView,
  view,
  views,
  user,
  updateStaffFilterState,
  updateTypeSelector,
  meetingTypes,
  type,
  setCountry
}) => {
  const { t } = useTranslation();
  const navigate = action => onNavigate(action);
  const setView = view => onView(view);
  const { hasMultipleCountries } = useLoggedInUser();

  const labels = {
    month: t('Month'),
    week: t('Week'),
    day: t('Day')
  };

  return (
    <div className='lavendla-calendar-toolbar__wrapper'>
      <div className='lavendla-calendar-toolbar__header'>
        <h1 className='lavendla-calendar-toolbar__label'>{label}</h1>
        <div className='header-with-filters__filters'>
          {hasMultipleCountries && (
            <div className='header-with-filters__filters__filter'>
              <UserCountryDropdown
                selected={country?.id}
                onChange={countries => setCountry(countries[0])}
              />
            </div>
          )}
          <AdminOnly>
            <div className='header-with-filters__filters__filter'>
              <UserDropdown
                all
                countryId={country?.id}
                user={user}
                onChange={updateStaffFilterState}
              />
            </div>
          </AdminOnly>
        </div>
      </div>
      <div className='lavendla-calendar-toolbar__container'>
        <div className='lavendla-calendar-toolbar__btn-container'>
          <button
            type='button'
            className='lavendla-calendar-toolbar__btn prev'
            aria-label={t('Previous')}
            onClick={_ => navigate('PREV')}
          />
          <button
            type='button'
            className='lavendla-calendar-toolbar__btn next'
            aria-label={t('Next')}
            onClick={_ => navigate('NEXT')}
          />
          <button
            type='button'
            className='lavendla-calendar-toolbar__btn today'
            aria-label={t('Today')}
            onClick={_ => navigate('TODAY')}
          >
            {t('Today')}
          </button>
        </div>
        <div className='lavendla-calendar-toolbar__filter-container'>
          {views.length > 1
            ? (
              <select
                onChange={({ target: { value } }) => setView(value)}
                value={view}
              >
                {views.map(name => (
                  <option key={name} value={name}>{labels[name]}</option>
                ))}
              </select>
              )
            : (
              <span>&nbsp;</span>
              )}
          <select
            onChange={updateTypeSelector}
            value={type}
          >
            <option value=''>{t('All meeting types')}</option>
            {Object.keys(meetingTypes).map(key => (
              <option key={`option-${key}`} value={key}>{meetingTypes[key]}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

Toolbar.propTypes = {
  country: PropTypes.object,
  label: PropTypes.string,
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  view: PropTypes.string,
  views: PropTypes.arrayOf(PropTypes.string),
  user: PropTypes.number,
  updateStaffFilterState: PropTypes.func.isRequired,
  updateTypeSelector: PropTypes.func.isRequired,
  meetingTypes: PropTypes.object.isRequired,
  type: PropTypes.string,
  setCountry: PropTypes.func.isRequired
};

export default Toolbar;
