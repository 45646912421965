import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, LoadingButton } from 'FormElements/index';
import PropTypes from 'prop-types';
import Textarea from 'FormElements/Textarea';

function SingleReview ({ review, isLoading, updateRequiresModeration, cancel }) {
  const { t } = useTranslation();
  const [currentReview, setCurrentReview] = useState(review);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleChange = (field, value) => {
    const updatedReview = { ...currentReview, [field]: value };

    setCurrentReview(updatedReview);
  };

  const dispatchRequiresModeration = () => {
    setErrorMessage(null);

    if (currentReview.requires_moderation && !currentReview.requires_moderation_description) {
      setErrorMessage(t('You must give a reason for the moderation request'));

      return;
    }

    updateRequiresModeration(currentReview);
  };

  return (
   <>
      <div className={'review-requires-moderation-modal-content'}>
        <div className={'review-requires-moderation-modal-content__checkbox-container'}>
          <Checkbox
            data-cy='currentReview.requires_moderation'
            desc={t('This review requires moderation')}
            label={t('This review requires moderation')}
            onChange={value => handleChange('requires_moderation', value)}
            defaultChecked={!!currentReview.requires_moderation}
          />
        </div>
        <Textarea
          onChange={value => handleChange('requires_moderation_description', value)}
          label={t('Add a description')}
          rows={3}
          value={currentReview.requires_moderation_description}
          floatingLabel
        />
      </div>

      {errorMessage && (
        <span className={'review-requires-moderation-modal-content__error'}>{errorMessage}</span>
      )}

      <div className={'modal__footer'}>
        <div className={'btn-group review-requires-moderation-modal-content__buttons'}>
          <button onClick={cancel} className="btn btn-secondary">
            {t('Cancel')}
          </button>

          <LoadingButton
            isLoading={isLoading}
            onClick={dispatchRequiresModeration}
          >
            {t('Update')}
          </LoadingButton>
        </div>
      </div>
   </>
  );
}

SingleReview.propTypes = {
  review: PropTypes.object,
  updateRequiresModeration: PropTypes.func,
  cancel: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default SingleReview;
