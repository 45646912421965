import { combineReducers } from 'redux';
import competenceAreas from './competenceAreas';
import products from './products';
import locations from './locations';
import memorialPageImages from './memorialPageImages';
import suppliers from './suppliers';
import settings from './settings';
import users from './users';
import notes from './notes';
import appState from './appState';
import invitations from './invitations';
import orderAssigner from './orderAssigner';
import tasks from './tasks';
import order from './order';
import bonusReports from './bonusReports';
import countries from './countries';
import bonusAmounts from './bonusAmounts';
import callRoutes from './callRoutes';
import customerRecords from './customerRecords';
import tags from './tags';
import coupons from './coupons';
import wills from './wills';
import flowerFee from './flowerFee';
import reviews from './reviews';

export default combineReducers({
  locations,
  competenceAreas,
  products,
  memorialPageImages,
  suppliers,
  settings,
  users,
  notes,
  appState,
  invitations,
  orderAssigner,
  tasks,
  order,
  bonusReports,
  countries,
  bonusAmounts,
  coupons,
  callRoutes,
  customerRecords,
  tags,
  wills,
  flowerFee,
  reviews
});
