export function isInvoiceTask (task) {
  return !!task.is_invoice_task;
}

export function isOfferTask (task) {
  return !!task.is_offer_task;
}

export function isUpdatedOfferTask (task) {
  return !!task.is_updated_offer_task;
}

export function isPaymentTask (task) {
  return !!task.is_payment_task;
}

export function isTransportTask (task) {
  return task.id === 18 || task.name === 'Transportbeställning';
}

export function isCreateLawyerOrderTask (task) {
  return task.id === 31 || task.name === 'Boka kostnadsfri juridisk konsultation';
}

export function isCreateTombstoneOrderTask (task) {
  return task.id === 27 || task.id === 231 || task.name === 'Önskar hjälp med gravsten';
}

export function isCreateTherapyOrderTask (task) {
  return task.id === 257 || task.name === 'Skapa terapiärende';
}

export function isCreateMemorialPageTask (task) {
  return task.id === 87 || task.name === 'Skicka minnessida till kund';
}

export function isReviewEmailTask (task) {
  return !!task.is_review_email_task;
}

export function canSendTransportTask (order, task) {
  if (!isTransportTask(task)) {
    return true;
  }

  const bookingTask = order.tasks.find(({ id }) => id === 2);
  if (!bookingTask) {
    return true;
  }

  return !!bookingTask.pivot.completed;
}

export function canSendInvoiceTask (order, task) {
  if (!isInvoiceTask(task)) {
    return true;
  }

  return order.products.length;
}

export const legacyShortcodeDict = {
  address: 'order.company_address',
  site: 'order.company_address',
  ovrigInformation: 'order.custom_info',
  ordernummer: 'order.number',
  orderId: 'order.id',
  begravningsstad: 'order.location',
  radgivare: 'order.user',

  avbetalning: 'invoice_options.is_installment',

  kundFornamn: 'customer.first_name',
  kundEfternamn: 'customer.last_name',
  kundPersonnummer: 'customer.social_security_number',
  kundRelation: 'customer.relation_to_deceased',
  kundAdress: 'customer.address_1',
  kundPostnummer: 'customer.postcode',
  kundOrt: 'customer.city',
  kundEmail: 'customer.email',
  kundTelefon: 'customer.phone',

  avlidenFornamn: 'deceased.first_name',
  avlidenEfternamn: 'deceased.last_name',
  avlidenPersonnummer: 'deceased.social_security_number',
  avlidenDatum: 'deceased.date',
  hemforsamling: 'deceased.parish',
  avlidenPlats: 'deceased.city',
  klarForUpphamtning: 'deceased.pick_up_date',
  egnaKlader: 'deceased.own_clothes',

  ceremoniplats: 'funeral.ceremony_place',
  ordning: 'funeral.divine_economy',
  typ: 'funeral.type',
  begravningsplats: 'funeral.graveyard',
  grav: 'funeral.grave_place',
  bartacke: 'funeral.canope',
  musiker: 'funeral.soloist',
  organist: 'funeral.organist',
  vatkmastare: 'funeral.janitor',
  ceremoniKladsel: 'funeral.clothing',
  forvantatAntalGaster: 'funeral.expected_number_of_guests',
  antalRepresentanter: 'funeral.num_representatives',
  officiant: 'funeral.officiant',

  gravsattning: 'entombment.entombment',
  anhorigaNarvarar: 'entombment.participation',

  minnesstundsLokal: 'memorial.place',
  osaMinnesstund: 'memorial.rsvp',
  fortaringMinnesstund: 'memorial.food',
  minnesstund: 'memorial.attendees',
  antalGaster: 'memorial.num_attendees',

  bisattningslokal: 'transport.coffin_storage',
  taxaEftertransport: 'transport.invoice_to_swedish_church',
  barareAttFakturera: 'transport.num_coffin_carriers',
  transportTaxaEftertransport: 'transport.invoice_to_swedish_church',
  antalBarareAttFakturera: 'transport.num_coffin_carriers',

  lankTillMinnessidan: 'memorial_page.lavendla_post_url',
  epostDelningslankMinnessida: 'memorial_page.email_sharelink',

  blommor: 'products.flowers',
  kista: 'products.coffin',
  urna: 'products.urn',
  blomlista: 'products.flowers_list',
  gravsten: 'products.tombstone',
  programblad: 'products.has_programme',
  ceremonivard: 'products.has_host',
  arvodeOfficiant: 'products.official_fee',

  transport: 'suppliers.transportoer',
  florist: 'suppliers.blomleverantoer',
  krematoriumNamnTel: 'suppliers.krematorium',
  barhusNamnTel: 'suppliers.barhus',
  barhus: 'suppliers.barhus',
  representant: 'suppliers.representant',
  krematorium: 'suppliers.krematorium',
  minnesfond: 'suppliers.minnesfond',
  'deceased.estate_agent': 'suppliers.fastighetsmaklare',
  'deceased.morgue': 'suppliers.barhus',
  motesDatum: 'appointment.customer_meeting.start_date',
  motesTid: 'appointment.customer_meeting.start_time',
  ceremoniDatum: 'appointment.ceremony.start_date',
  startTid: 'appointment.ceremony.start_time',
  slutTid: 'appointment.ceremony.end_time',
  datumVisning: 'appointment.viewing.start_date',
  startTidVisning: 'appointment.viewing.start_time',
  gravsattningDatumOchTid: 'appointment.entombment.datetime',
  startTidMinnesstund: 'appointment.memorial.start_time',
  slutTidMinnesstund: 'appointment.memorial.end_time'
};
