import { SET_PAGINATION, SET_REVIEW, SET_REVIEWS, SET_IS_LOADING } from '../actions/reviews';

const initialState = {
  review: null,
  reviews: [],
  pagination: null,
  isLoading: true
};

export default function wills (state = initialState, { type, payload }) {
  switch (type) {
    case SET_REVIEW: {
      return {
        ...state,
        review: payload
      };
    }

    case SET_REVIEWS: {
      return {
        ...state,
        reviews: payload
      };
    }

    case SET_PAGINATION: {
      return {
        ...state,
        pagination: payload
      };
    }

    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: payload
      };
    }

    default:
      return state;
  }
}
