import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from 'FormElements/index';
import PropTypes from 'prop-types';
import Textarea from 'FormElements/Textarea';

function ReplyForm ({ review, isLoading, updateReply }) {
  const { t } = useTranslation();
  const [currentReview, setCurrentReview] = useState(review);

  const handleChange = (value) => {
    const updatedReview = { ...currentReview, reply: value };

    setCurrentReview(updatedReview);
  };

  const dispatchReply = () => {
    updateReply(currentReview);
  };

  return (
    <div className={'review-reply-form'}>
      <div className={'review-reply-form__editor'}>
        <Textarea
          onChange={value => handleChange(value)}
          placeholder={t('Write a reply...')}
          floatingLabel={false}
          rows={2}
          value={currentReview.reply}
        />
      </div>

      <LoadingButton
        isLoading={isLoading}
        onClick={dispatchReply}
      >
        {t('Store reply')}
      </LoadingButton>
    </div>
  );
}

ReplyForm.propTypes = {
  review: PropTypes.object,
  updateReply: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default ReplyForm;
