import React, { useState, useEffect } from 'react';
import ReactSVG from 'react-svg';
import PropTypes from 'prop-types';

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  ImageWithZoom,
  DotGroup
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

const ProductGallery = ({ product, variationId }) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    setImages(getImages);
  }, [variationId]);

  const getImages = () => {
    if (!product) {
      return [];
    }

    if (product.type === 'simple') {
      return product.images;
    }

    // eslint-disable-next-line no-prototype-builtins
    if (variationId > 0 && product.variationImages.hasOwnProperty(variationId)) {
      return product.variationImages[variationId];
    }

    return product.images;
  };

  return (
    <div className='single-product__gallery-container'>
      <CarouselProvider
        className='product-gallery'
        dragEnabled={true}
        hasMasterSpinner={true}
        infinite={true}
        naturalSlideWidth={100}
        naturalSlideHeight={100}
        totalSlides={images && images.length ? images.length : 0}
        touchEnabled={true}
      >
        <Slider>
          {(!images || images.length === 0) || images.map((image, index) => (
            <Slide index={index} key={`${index}_${image.id}`}>
              <ImageWithZoom src={image.sizes?.funnelSingle} alt={image.alt}/>
            </Slide>
          ))}
        </Slider>

        {(!images || images.length === 1) || <>
          <ButtonBack>
            <ReactSVG className='carousel__back-button-svg' src='/icons/arrow-right.svg'/>
          </ButtonBack>
          <ButtonNext>
            <ReactSVG className='carousel__next-button-svg' src='/icons/arrow-left.svg'/>
          </ButtonNext>
          <DotGroup/>
        </>}
      </CarouselProvider>
    </div>
  );
};

ProductGallery.propTypes = {
  product: PropTypes.object,
  variationId: PropTypes.number
};

export default ProductGallery;
