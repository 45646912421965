import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { formatAsOption, generateId } from 'Library/functions';
import useCountries from 'ReduxHooks/useCountries';

const CountryDropdown = ({
  id = generateId(),
  selected = [],
  onChange = null,
  isMulti = false,
  isClearable = false,
  placeholder = isMulti ? '-- Välj länder --' : '-- Välj land --',
  ...rest
}) => {
  const { fetchCountries, countries } = useCountries();

  useEffect(() => {
    fetchCountries();
  }, []);

  function normalizeValue (data) {
    if (!data) {
      return [];
    }

    if (!Array.isArray(data)) {
      data = [data];
    }

    data = data.map(item => {
      if (typeof item === 'string' || typeof item === 'number') {
        return parseInt(item);
      }

      if (typeof item.id !== 'undefined') {
        return parseInt(item.id);
      }

      if (typeof item.value !== 'undefined') {
        return parseInt(item.value);
      }

      return null;
    }).filter(data => data);

    if (!data.length) {
      return [];
    }

    return countries.filter(country => data.includes(country.id));
  }

  const update = values => {
    onChange(normalizeValue(values));
  };

  function getOptions () {
    return [...countries].map(formatAsOption);
  }

  function getValue () {
    if (!countries.length) {
      return null;
    }

    return normalizeValue(selected).map(formatAsOption);
  }

  return (
    <Select
      id={id}
      isMulti={isMulti}
      onChange={update}
      value={getValue()}
      options={getOptions()}
      placeholder={placeholder}
      isClearable={isClearable}
      className='lavendla-select'
      classNamePrefix='lavendla-select'
      {...rest}
    />
  );
};

CountryDropdown.propTypes = {
  selected: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  id: PropTypes.string,
  placeholder: PropTypes.string
};

export default CountryDropdown;
