import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getStarsUrl, getStarLabel } from 'Library/TrustpilotHelpers';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const TrustPilot = ({ user }) => {
  const { t } = useTranslation();

  if (!user || !user.reviews.length || user.number_of_reviews === '') {
    return null;
  }

  // Filter out reviews of type 'custom'
  user.reviews = user.reviews.filter(({ source }) => source !== 'custom');

  // Recalculate the number of reviews
  user.number_of_reviews = user.reviews.length;

  const sortedLimitedReviews = structuredClone(user.reviews).reverse().splice(0, 5);

  return (
    <div className='trustpilot__wrapper'>
      <div
        className='dashboard__section-small-heading'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span>
          {t('Your')} <strong>{sortedLimitedReviews.length} {t('latest reviews')}</strong>
        </span>
        <Link to={'/reviews'}>
          {t('Show all reviews')}
        </Link>
      </div>
      <div className='trustpilot__container'>
        {sortedLimitedReviews.map(({ title, rating, review_date: date, review, customer_name: customer }, index) => {
          return (
            <div key={index} className='trustpilot__single'>
              <div className='trustpilot__single-header'>
                <img
                  src={getStarsUrl(rating)}
                  alt={getStarLabel(rating)}
                />
                <span className='date'>{moment(date).format('D MMMM YYYY')}</span>
              </div>
              <h3>{title}</h3>
              <p>{review}</p>
              <span className='customer'>{customer}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

TrustPilot.propTypes = {
  user: PropTypes.object.isRequired
};

export default TrustPilot;
