import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { formatAsOption, formatUser, generateId } from 'Library/functions';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';

const SiteDropdown = ({
  id = generateId(),
  selected = [],
  onChange = null,
  isClearable = false,
  placeholder = null,
  country: { sites } = {},
  ...rest
}) => {
  const { loggedInUser } = useLoggedInUser();
  const { t } = useTranslation();

  function normalizeValue (data) {
    if (!data) {
      return [];
    }

    if (!Array.isArray(data)) {
      data = [data];
    }

    data = data.map(item => {
      if (typeof item === 'string' || typeof item === 'number') {
        return parseInt(item);
      }

      if (typeof item.id !== 'undefined') {
        return parseInt(item.id);
      }

      if (typeof item.value !== 'undefined') {
        return parseInt(item.value);
      }

      return null;
    }).filter(data => data);

    if (!data.length) {
      return [];
    }

    return sites.filter(site => data.includes(site.id));
  }

  const update = values => {
    onChange(values);
  };

  function getOptions () {
    return [...sites].map(formatAsOption);
  }

  function getValue() {
    if (!sites.length) {
      return null;
    }

    const selectedSite = sites.find(site => site.id === selected);
    return selectedSite ? normalizeValue(selectedSite).map(formatAsOption) : null;
  }

  if (!sites) {
    return null;
  }

  return (
    <Select
      id={id}
      onChange={update}
      value={getValue()}
      options={getOptions()}
      placeholder={t('Choose site')}
      isClearable={isClearable}
      className='lavendla-select'
      classNamePrefix='lavendla-select'
      {...rest}
    />
  );
};

SiteDropdown.propTypes = {
  selected: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  isClearable: PropTypes.bool,
  id: PropTypes.string,
  placeholder: PropTypes.string
};

export default SiteDropdown;
