import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function RoundFlag ({ order }) {
  return (
    <div className='round-flag-icon'>
      {order.country && order.country.code === 'global'
        ? (
          <FontAwesomeIcon icon={['fas', 'earth-americas']} />
          )
        : (
          <img src={`/icons/${order.country.code}.svg`} alt={order.country.name} />
          )}
    </div>
  );
}

RoundFlag.propTypes = {
  order: PropTypes.object
};

export default RoundFlag;
