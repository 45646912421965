import React, { useEffect, useState } from 'react';
import { HeaderWithFilters } from 'Layout/index';
import ListingPagination from 'Components/ListingPagination';
import { useTranslation } from 'react-i18next';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import PropTypes from 'prop-types';
import UserCountryDropdown from 'Components/UserCountryDropdown';
import useReviews from 'ReduxHooks/useReviews';
import { getID } from 'Components/localstorage';
import SingleReview from './SingleReview';

function ReviewsArchive ({ history }) {
  const { hasMultipleCountries } = useLoggedInUser();
  const [country, setCountry] = useState([]);
  const [currentPage, setCurrentPage] = useState(new URLSearchParams(history.location.search).get('page') || 1);
  const { fetchReviews, reviews, pagination, isLoading, setReview, persistReviewForReply, persistReviewForRequiresModeration } = useReviews();
  const { isAdmin } = useLoggedInUser();
  const { t } = useTranslation();

  useEffect(() => {
    getReviews();
  }, [country]);

  const getReviews = () => {
    fetchReviews(getDefaultFilters());
  };

  const switchPage = (direction) => {
    let page = 1;

    if (direction === 'previous') {
      if (currentPage > 1) {
        page = parseInt(currentPage) - 1;
      }
    }

    if (direction === 'next') {
      page = parseInt(currentPage) + 1;
    }

    setCurrentPage(page);

    let filters = getDefaultFilters();
    filters.page = page;

    fetchReviews(filters);
  };

  const getDefaultFilters = () => {
    return {
      countryId: country?.id,
      userId: isAdmin ? -1 : getID(),
      page: currentPage
    };
  }

  const updateReply = async (review) => {
    const updatedReview = { ...review };

    await setReview(updatedReview);
    await persistReviewForReply();
  };

  const updateRequiresModeration = async (review) => {
    const updatedReview = { ...review };

    await setReview(updatedReview);
    await persistReviewForRequiresModeration();
  };

  return (
    <div className='registry'>
      <HeaderWithFilters header={t('Reviews')}>
        {hasMultipleCountries && (
          <div className='header-with-filters__filters__filter'>
            <UserCountryDropdown
              isClearable
              selected={country?.id}
              onChange={selectedCountry => setCountry(selectedCountry[0])}
            />
          </div>
        )}
      </HeaderWithFilters>

      <div>
        {(!reviews.length && !isLoading)
          ? (<div>{t('No reviews found')}</div>)
          : (
            reviews.map((review) => (
              <SingleReview
                review={review}
                key={review.id}
                updateReply={updateReply}
                updateRequiresModeration={updateRequiresModeration}
                refreshReviews={getReviews}
              />
            ))
          )}
      </div>

      <ListingPagination
        pagination={pagination}
        switchPage={switchPage}
      />
    </div>
  );
}

ReviewsArchive.propTypes = {
  history: PropTypes.object,
};

export default ReviewsArchive;
