import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getEndpoint } from 'Library/OrderHelperFuntions';
import API from '../stores/API';
import { AnimatePresence, motion } from 'framer-motion';
import { animateOpacity } from 'Library/motion';
import { If } from 'Conditionals';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';

function NumNewOrders ({ isLawyerOrder = false, isTombstoneOrder = false, isTherapyOrder = false, isCeremonyOrder = false }) {
  const [numOrders, setNumOrders] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { loggedInUser: { countries } } = useLoggedInUser();
  const countryIds = countries.map(({ id }) => id).join(',');

  useEffect(() => {
    getOrders();
  }, []);

  async function getOrders () {
    if (isLoading) {
      return;
    }

    const endpoint = getEndpoint({ isLawyerOrder, isTombstoneOrder, isTherapyOrder, isCeremonyOrder });
    const uri = `${endpoint}/available?limit=100&count=1&country_id=${countryIds}`;

    setIsLoading(true);
    const { status, data } = await API.GET(uri);
    setIsLoading(false);

    if (status === 'ok') {
      setNumOrders(data);
    }
  }

  return (
    <AnimatePresence>
      <If condition={numOrders > 0}>
        <motion.span {...animateOpacity} className='num-new-orders'>
          {numOrders}
        </motion.span>
      </If>
    </AnimatePresence>
  );
}

NumNewOrders.propTypes = {
  isLawyerOrder: PropTypes.bool,
  isTombstoneOrder: PropTypes.bool,
  isTherapyOrder: PropTypes.bool,
  isCeremonyOrder: PropTypes.bool
};

export default NumNewOrders;
