import React, { useState } from 'react';
import Imgix from 'react-imgix';
import Skeleton from 'react-loading-skeleton';
import MemorialPageFileUpload from './MemorialPageFileUpload';
import LavendlaDatePicker from 'Components/LavendlaDatePicker';
import { Fieldset, Input, LoadingButton } from 'FormElements';
import { If } from 'Conditionals';
import { OBITUARY_IMAGE, FUNERAL_PROGRAM_FILE } from 'Library/MemorialPageHelpers';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import useMemorialPage from 'ReduxHooks/useMemorialPage';
import { useTranslation } from 'react-i18next';

function MemorialPageFilesForm () {
  const { t } = useTranslation();
  const { updateField, getCountryCode } = useSingleOrder();
  const { findImageOfType, memorialPage, detachImage } = useMemorialPage();
  const [isLoadingObituary, setIsLoadingObituary] = useState(false);
  const [isLoadingFuneralProgram, setIsLoadingFuneralProgram] = useState(false);
  const obituaryImage = findImageOfType(OBITUARY_IMAGE) || false;
  const funeralProgramImage = findImageOfType(FUNERAL_PROGRAM_FILE) || false;
  const countryCode = getCountryCode();

  const removeObituaryImage = async () => {
    setIsLoadingObituary(true);
    await detachImage(obituaryImage.id);
    setIsLoadingObituary(false);
  };

  const removeFuneralProgramImage = async () => {
    setIsLoadingFuneralProgram(true);
    await detachImage(funeralProgramImage.id);
    setIsLoadingFuneralProgram(false);
  };

  return (
    <Fieldset
      legend={t('Upload files for the obituary and the funeral program')}
      className='memorial-page-files-form'
    >
      <MemorialPageFileUpload />

      <Fieldset
        legend={t('Obituary')}
        className='memorial-page-files-form__fieldset'
        subField
      >
        <If condition={isLoadingObituary}>
          <Skeleton width={150} height={250} />
        </If>

        <If condition={!obituaryImage}>
          <p className='memorial-page-files-form__no-image'><em>{t('No file found')}</em></p>
        </If>

        <If condition={!isLoadingObituary && obituaryImage}>
          <Imgix
            className='memorial-page-files-form__image'
            src={obituaryImage.url}
            width={150}
            imgixParams={{
              w: 150
            }}
          />

          <div className='memorial-page-files-form__info'>
            <Input
              type='text'
              value={memorialPage.obituary_image_publication}
              data-cy='memorial_page.obituary_image_publication'
              placeholder={t('The newspaper that published the obituary')}
              onChange={publication => updateField('memorial_page.obituary_image_publication', publication)}
            />
            <LavendlaDatePicker
              className='input'
              countryCode={countryCode}
              placeholder={t('Published date')}
              date={memorialPage.obituary_image_publication_date}
              onChange={date => updateField('memorial_page.obituary_image_publication_date', date)}
              data-cy='memorial_page.obituary_image_publication_date'
            />
            <LoadingButton
              className='btn delete'
              disabled={isLoadingObituary}
              onClick={removeObituaryImage}
            >
              {t('Remove the obituary')}
            </LoadingButton>
          </div>
        </If>
      </Fieldset>

      <Fieldset
        legend={t('Funeral program')}
        className='memorial-page-files-form__fieldset'
        subField
      >
        <If condition={isLoadingFuneralProgram}>
          <Skeleton width={250} height={350} />
        </If>

        <If condition={!funeralProgramImage}>
          <p className='memorial-page-files-form__no-image'><em>{t('No file found')}</em></p>
        </If>

        <If condition={!isLoadingFuneralProgram && funeralProgramImage}>
          <Imgix
            className='memorial-page-files-form__image'
            src={funeralProgramImage.url}
            width={250}
            imgixParams={{
              w: 250
            }}
          />

          <div className='memorial-page-files-form__info'>
            <LoadingButton
              className='btn delete'
              disabled={isLoadingFuneralProgram}
              onClick={removeFuneralProgramImage}
            >
              {t('Remove the funeral program')}
            </LoadingButton>
          </div>
        </If>
      </Fieldset>
    </Fieldset>
  );
}

export default MemorialPageFilesForm;
