import SparGetCustomerDetails from '../SparGetCustomerDetails';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../FormElements';
import Personnummer from 'personnummer';
import cpr from 'danish-ssn';
import { Notice } from 'Layout';
import { AnimatePresence, motion } from 'framer-motion';
import InfoModal from '../InfoModal';
import If from 'Conditionals/If';

/**
 * This will probably only be used in sweden so we'll
 * leave it untranslated for now
 *
 * @needst10ns
 */
function SocialSecurityNumber ({
  onChange,
  onUpdate,
  value = '',
  title = 'Personnummer',
  children = null,
  isCustomer = true,
  showRequiredIcon = true,
  type = 'spar'
}) {
  const [isValidNumber, setIsValidNumber] = useState(true);
  const motionProps = {
    initial: { opacity: 0, x: -20 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -20 }
  };
  let timeout;

  useEffect(() => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      if (!value) {
        setIsValidNumber(true);
        return;
      }

      if (type === 'other') {
        setIsValidNumber(true);
        return;
      }

      if (type === 'spar') {
        setIsValidNumber(Personnummer.valid(value));
      }

      if (type === 'cpr') {
        const newValue = value.replace('-', '');
        setIsValidNumber(newValue.length === 10 && cpr.isValid(newValue));
      }
      // todo: validate cpr
    }, 2000);
  }
  , [value]);

  return (
    <div className='social-security-number'>
      <div className='row'>
        <Input
          onChange={value => onChange(value)}
          name='social-security-number'
          data-cy='social-security-number'
          label={title}
          className='col-6'
          value={value}
          showRequiredIcon={showRequiredIcon}
          floatingLabel
        />
        <If condition={type !== 'other'}>
          <div className='col-6'>
            <SparGetCustomerDetails
              socialSecurityNumber={value}
              onUpdate={values => onUpdate({ ...values, social_security_number: value })}
              disabled={!isValidNumber || !value}
              type={type}
            />
          </div>
          {children ? (
            <div className='col-4'>
              <InfoModal title={title}>
                {children}
              </InfoModal>
            </div>
          ) : null}
        </If>
      </div>
      <If condition={type !== 'other'}>
        <div className='social-security-number__info-text'>
          <p className='social-security-number__info-text__spar'>
            {type === 'spar' ? (
              <>För att hämta personuppgifter via SPAR så krävs ett giltigt, svenskt personnummer i formatet YYYYMMDDNNNN</>
            ) : (
              <>For at hente personoplysninger via CPR kræves et gyldigt CPR-nummer i formatet DDMMÅÅ-NNNN.</>
            )}
          </p>
        </div>
      </If>
      {!isValidNumber ? (
        <AnimatePresence>
          <motion.div {...motionProps}>
            {type === 'spar' ? (
              <Notice type='notice'>
                {isCustomer ? (
                  <>
                    <p><strong>OBS! Ej giltigt svenskt personnummer.</strong> Svenska personnummer ska anges i formatet <em>YYYYMMDDNNNN</em>.</p>
                    <p>Är kunden utländsk ska det personnumret anges. I dessa fall gäller förskottsbetalning. Information om detta kommer automatiskt stå i offerten.</p>
                    <p>Kontrollera att eventuellt organisationsnummer är korrekt.</p>
                  </>
                ) : (
                  <>
                    <p><strong>OBS! Ej giltigt svenskt personnummer.</strong> Svenska personnummer ska anges i formatet <em>YYYYMMDDNNNN</em>.</p>
                    <p>Gäller det ett dödfött barn använd födelsedatum0000. T.ex. om barnet är framfött 4 november 2020: 202011040000. Övrig bra information finns på
                      {' '}
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href='https://www.spadbarnsfonden.se/'
                      >spadbarnsfonden.se
                      </a>
                    </p>
                    <p>Om avliden är ex asylsökande kan man skriva in samordningsnumret som alla som inte är folkbokförda i Sverige får. Läs mer på
                      {' '}
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href='https://www.skatteverket.se/privat/skatter/internationellt/bosattutomlands/samordningsnummer.4.53a97fe91163dfce2da80001279.html'
                      >skattverket.se
                      </a>
                    </p>
                  </>
                )}
              </Notice>
            ) : (
              <Notice type='notice'>
                <p><strong>BEMÆRK! Ikke gyldigt CPR-nummer.</strong> CPR-nummer skal angives i formatet <em>DDMMÅÅ-NNNN</em>.</p>
              </Notice>
            )}
          </motion.div>
        </AnimatePresence>
      ) : null}
    </div>
  );
}

SocialSecurityNumber.propTypes = {
  onChange: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  value: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  isCustomer: PropTypes.bool,
  showRequiredIcon: PropTypes.bool,
  type: PropTypes.oneOf(['spar', 'cpr'])
};

export default SocialSecurityNumber;
