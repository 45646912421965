import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getSupplierTypes } from 'Library/functions';
import { useTranslation } from 'react-i18next';

// Components
import { Link } from 'react-router-dom';
import { HeaderWithFilters, Table } from 'Layout';
import { Input } from 'FormElements';
import { AdminOnly } from 'Components/Conditionals';
import ListingPagination from 'Components/ListingPagination';
import LocationDropdown from 'Components/LocationDropdown';
import Spinner from 'Components/Spinner';
import SuppliersExport from 'Components/SuppliersExport';
import TypeDropdown from 'Components/TypeDropdown';
import UserCountryDropdown from 'Components/UserCountryDropdown';

// Redux
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import useSuppliers from 'ReduxHooks/useSuppliers';

function Suppliers () {
  const { t } = useTranslation();
  const { isAdmin, hasMultipleCountries, loggedInUser: { country: userCountry } } = useLoggedInUser();
  const { suppliers, pagination, isLoading, fetchSuppliers } = useSuppliers();
  const [filters, setFilters] = useState({
    type: null,
    country: userCountry,
    location: null,
    search: '',
    page: 1
  });

  useEffect(() => {
    fetchSuppliers(filters);
  }, [filters]);

  const changeCountry = (countries) => {
    setFilters({
      ...filters,
      page: 1,
      location: null,
      country: countries.length ? countries[0] : null
    });
  };

  const changeLocation = (location) => {
    setFilters({
      ...filters,
      page: 1,
      location
    });
  };

  const changeType = type => {
    setFilters({
      ...filters,
      page: 1,
      type
    });
  };

  const changeSearch = search => {
    setFilters({
      ...filters,
      page: 1,
      search
    });
  };

  function translateSupplierType (type = null) {
    if (!type) {
      return '-- ';
    }

    const supplierType = getSupplierTypes().find(({ value }) => value === type);

    return supplierType ? supplierType.label : '-- ';
  }

  function paginate (direction = 'next') {
    if (pagination.current_page === 1 && direction === 'previous') {
      return;
    }

    if (pagination.current_page === pagination.total_pages && direction === 'next') {
      return;
    }

    if (direction === 'next') {
      setFilters({
        ...filters,
        page: filters.page + 1
      });
      return;
    }

    setFilters({
      ...filters,
      page: filters.page - 1
    });
  }

  return (
    <div className='suppliers'>
      <HeaderWithFilters header={t('Suppliers')}>
        {isAdmin
          ? (
            <div className='header-with-filters__filters__filter'>
              <Link className='btn' to='/leverantorer/skapa'>{t('Create new')}</Link>
            </div>
            )
          : null}

        <div className='header-with-filters__filters__filter'>
          <Input
            className='filter-box-suppliers__search-field'
            placeholder='Sök efter leverantör'
            value={filters.search}
            onChange={changeSearch}
          />
        </div>

        {hasMultipleCountries &&
         (
           <div className='header-with-filters__filters__filter'>
             <UserCountryDropdown
               selected={filters.country}
               onChange={changeCountry}
               isClearable
             />
           </div>
         )}

        <div className='header-with-filters__filters__filter'>
          <LocationDropdown
            countryId={filters.country ? filters.country.id : null}
            value={filters.location}
            onChange={changeLocation}
          />
        </div>

        <div className='header-with-filters__filters__filter'>
          <TypeDropdown
            value={filters.type}
            onChange={changeType}
          />
        </div>

        <AdminOnly>
          <div className='header-with-filters__filters__filter'>
            <SuppliersExport {...filters} />
          </div>
        </AdminOnly>
      </HeaderWithFilters>

      {isLoading
        ? (
          <Spinner />
          )
        : (
          <Table
            thead={[
              t('Name'), t('Contact person'), t('Type')
            ]}
            isLoading={isLoading}
          >
            {suppliers.map(({ id, name, type, contact_person: contact }) => (
              <tr key={id}>
                <td>
                  <Link to={`/leverantorer/leverantor/${id}`}>{name}</Link>
                </td>
                <td>{contact || '-- '}</td>
                <td>{translateSupplierType(type)}</td>
              </tr>
            ))}
          </Table>
          )}
      <ListingPagination
        switchPage={direction => paginate(direction)}
        pagination={pagination}
      />
    </div>
  );
}

Suppliers.propTypes = {
  match: PropTypes.object.isRequired
};

export default Suppliers;
