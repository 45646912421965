import React from 'react';
import PropTypes from 'prop-types';
import { isInvoiceTask } from 'Library/Tasks';
import { Notice } from 'Layout';
import { useTranslation } from 'react-i18next';

const VismaInvoiceWarning = ({ task }) => {
  const { t } = useTranslation();
  if (!isInvoiceTask(task)) {
    return null;
  }

  return (
    <Notice
      heading={t('Visma invoice information')}
      type='warning'
    >
      <p>{t('The order data will be sent to Visma and an invoice will be created within an hour.')}</p>
    </Notice>
  );
};

VismaInvoiceWarning.propTypes = {
  order: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired
};

export default VismaInvoiceWarning;
