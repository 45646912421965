import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Input, Select, LoadingButton, Toggle } from 'FormElements';
import UserCountryDropdown from 'Components/UserCountryDropdown';

// Redux
import { TYPE_FUNERAL, TYPE_LEGAL, TYPE_THERAPY, TYPE_CEREMONY } from 'ReduxActions/competenceAreas';
import useCompetenceAreas from 'ReduxHooks/useCompetenceAreas';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';

function CompetenceAreaForm ({ competenceArea = null }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { loggedInUser: { country: userCountry } } = useLoggedInUser();
  const [isLoading, setIsLoading] = useState(false);
  const { persist, create, destroy } = useCompetenceAreas();
  const [updatedArea, setUpdatedArea] = useState(competenceArea || {
    name: '',
    type: TYPE_FUNERAL,
    duration: null,
    exclude_from_api: false,
    sort_value: 0,
    country: userCountry
  });

  async function onSubmit () {
    let result = null;

    setIsLoading(true);
    if (competenceArea) {
      await persist(updatedArea);
    } else {
      result = await create(updatedArea);
    }
    setIsLoading(false);

    if (result) {
      redirectToArchives();
    }
  }

  async function onDelete () {
    if (!window.confirm(t('Are you sure you want to delete this competence area?'))) {
      return;
    }

    const response = await destroy(competenceArea.id);

    if (response) {
      redirectToArchives();
    }
  }

  function redirectToArchives () {
    setTimeout(() => {
      history.push('/kompetenser/');
    }, 1000);
  }

  return (
    <form className='order-tab__form'>
      <div className='row'>
        <Input
          label={t('Name')}
          data-cy='competence-area__name'
          value={updatedArea.name}
          className='col-8'
          onChange={name => setUpdatedArea({
            ...updatedArea,
            ...{ name }
          })}
          floatingLabel
        />
      </div>
      <div className='row'>
        <UserCountryDropdown
          selected={updatedArea.country.id}
          className='col-8'
          onChange={countries => setUpdatedArea({
            ...updatedArea,
            ...{ country: countries[0] }
          })}
        />
      </div>
      <div className='row'>
        <Input
          label={t('Time per order')}
          data-cy='competence-area__duration'
          value={updatedArea.duration}
          className='col-8'
          onChange={duration => setUpdatedArea({
            ...updatedArea,
            ...{ duration }
          })}
          floatingLabel
        />
      </div>
      <div className='row'>
        <Select
          label='Typ'
          data-cy='competence-area__type'
          value={updatedArea.type}
          className='col-8'
          options={{
            [TYPE_FUNERAL]: t('Funeral'),
            [TYPE_LEGAL]: t('Legal'),
            [TYPE_THERAPY]: t('Therapy'),
            [TYPE_CEREMONY]: t('Ceremony')

          }}
          onChange={type => setUpdatedArea({
            ...updatedArea,
            ...{ type }
          })}
          floatingLabel
        />
      </div>
      <div className='row'>
        <Input
          label={t('Sort value')}
          data-cy='competence-area__sort-value'
          value={updatedArea.sort_value}
          type='number'
          className='col-8'
          onChange={value => setUpdatedArea({
            ...updatedArea,
            ...{ sort_value: value }
          })}
          floatingLabel
        />
      </div>
      <div className='row'>
        <div className='col-8'>
          <Toggle
            label={t('Exclude from profile pages and sliders')}
            defaultChecked={!!updatedArea.exclude_from_api}
            onChange={value => setUpdatedArea({
              ...updatedArea,
              ...{ exclude_from_api: value }
            })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-8'>
          <LoadingButton
            isLoading={isLoading}
            data-cy='competence-area__submit'
            disabled={!updatedArea.name}
            onClick={() => onSubmit()}
          >
            {competenceArea ? t('Update') : t('Create')}
          </LoadingButton>
          {' '}
          {competenceArea
            ? (
              <LoadingButton
                className='delete'
                data-cy='competence-area__delete'
                onClick={() => onDelete()}
              >
                {t('Delete')}
              </LoadingButton>
              )
            : null}
        </div>
      </div>
    </form>
  );
}

CompetenceAreaForm.propTypes = {
  competenceArea: PropTypes.object
};

export default CompetenceAreaForm;
