import API from '../../stores/API';

export const SET_TAGS = 'SET_TAGS';

function recieveTags (payload) {
  return {
    type: SET_TAGS,
    payload
  };
}

export function resetTags () {
  return recieveTags([]);
}

export function getTags (countryId, type) {
  return async (dispatch) => {
    const { data } = await API.GET(`/tags/${countryId}?limit=0&type=${type}`);
    return dispatch(recieveTags(data));
  };
}
