import React, { useState, useEffect } from 'react';
import uniqueId from 'lodash/uniqueId';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Textarea = ({
  onChange,
  label = null,
  type = 'text',
  value = '',
  id = uniqueId('input_'),
  className = '',
  floatingLabel = false,
  ...rest
}) => {
  const [hasContent, setHasContent] = useState(!!value);

  // Ensure that null values are treated as empty strings
  if (value === null) {
    value = '';
  }

  useEffect(() => {
    setHasContent(!!value);
  }, [value]);

  const additionalClasses = {
    'floating-label': floatingLabel,
    'has-content': hasContent
  };

  return (
    <div className={classNames('input', 'textarea', additionalClasses, className)}>
      {label
        ? (
          <label htmlFor={id}>
            {label}
          </label>
          )
        : null}
      <textarea
        id={id}
        value={value}
        onBlur={() => setHasContent(!!value)}
        onFocus={() => setHasContent(true)}
        onChange={({ target }) => onChange(target.value)}
        {...rest}
      />
    </div>
  );
};

Textarea.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  floatingLabel: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default Textarea;
