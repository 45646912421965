import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'Components/Spinner';
import SupplierForm from 'Components/Suppliers/SupplierForm';
import useSupplier from 'ReduxHooks/useSupplier';
import SupplierImageUpload from 'Components/Suppliers/SupplierImageUpload';
import SupplierCustomColors from 'Components/Suppliers/SupplierCustomColors';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import UploadSupplierExpectedCostFile from 'Components/Suppliers/UploadSupplierExpectedCostFile';
import { useTranslation } from 'react-i18next';
import { AdminOnly, If } from 'Conditionals';
import { LOGO_WHITE } from 'ReduxActions/suppliers';
import { Notice } from 'Layout';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import classNames from 'classnames';

const SingleSupplier = props => {
  const { id } = props.match.params;
  const { t } = useTranslation();
  const { isAdmin } = useLoggedInUser();
  const [isLoading, setIsLoading] = useState(true);
  const { supplier, fetchSupplier } = useSupplier();
  const isOrganisation = supplier && (supplier.type === 'digitala_avtals_partner' || !!supplier.sync_as_org);

  useEffect(() => {
    getSupplier();
  }, [id]);

  async function getSupplier () {
    await fetchSupplier(id);
    setIsLoading(false);
  }

  if (isLoading || !supplier) {
    return (
      <Spinner />
    );
  }

  return (
    <div className='align-top'>
      <h2 className='page-title'>
        {t('Edit')} {supplier.name}
        {supplier.archived_at
          ? (
            <em>&nbsp;{t('(Inactive)')}</em>
            )
          : null}
      </h2>
      <If condition={!isAdmin}>
        <Notice>
          <h4>{t('If this information is incorrect')}</h4>
          <p>
            {t('If the information is incorrect regaring this supplier, please get in touch')} <a href='mailto:support@lavendla.se'>support@lavendla.se</a>
          </p>
        </Notice>
      </If>
      <div className='row'>
        <div className='col-8'>
          <Tabs>
            <TabList>
              <Tab>{t('Fields')}</Tab>
              <Tab className={classNames('react-tabs__tab', { hidden: !isAdmin })}>
                {t('Logos & files')}
              </Tab>
              <Tab className={classNames('react-tabs__tab', { hidden: (!isAdmin || !isOrganisation) })}>
                {t('Custom colors')}
              </Tab>
            </TabList>
            <TabPanel>
              <SupplierForm />
            </TabPanel>
            <TabPanel>
              <AdminOnly>
                <SupplierImageUpload />
                <SupplierImageUpload type={LOGO_WHITE} />
                <UploadSupplierExpectedCostFile supplier={supplier} />
              </AdminOnly>
            </TabPanel>
            <TabPanel>
              <AdminOnly>
                <SupplierCustomColors />
              </AdminOnly>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

SingleSupplier.propTypes = {
  match: PropTypes.object.isRequired
};

export default SingleSupplier;
