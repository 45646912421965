import { useDispatch, useSelector } from 'react-redux';
import { ROLES, updateLoggedInUser, toggleFavoritesAtTop } from '../actions/users';

function useLoggedInUser () {
  const dispatch = useDispatch();
  const { loggedInUser, isAuthenticated } = useSelector(state => state.users);

  let isAdmin = false;
  let isPaused = false;
  let isLegalAdvisor = false;
  let isTombstoneAdvisor = false;
  let isFuneralAdvisor = false;
  let isSupplier = false;
  let isTherapist = false;
  let isOfficiant = false;
  let hasMultipleCountries = false;
  let isConnectedToGlobalMarket = false;

  if (loggedInUser && isAuthenticated) {
    const { roles, paused_at: pausedAt, countries } = loggedInUser;

    isPaused = !!pausedAt;
    isAdmin = roles.includes(ROLES.ADMIN);
    isLegalAdvisor = roles.includes(ROLES.LEGAL);
    isTombstoneAdvisor = roles.includes(ROLES.TOMBSTONE);
    isFuneralAdvisor = roles.includes(ROLES.FUNERAL);
    isSupplier = roles.includes(ROLES.SUPPLIER);
    isTherapist = roles.includes(ROLES.THERAPIST);
    isOfficiant = roles.includes(ROLES.OFFICIANT);
    hasMultipleCountries = countries.length > 1;
    isConnectedToGlobalMarket = countries.filter((country) => country.code === 'global').length > 0;
  }

  return {
    loggedInUser,
    loggedInUserCountry: loggedInUser?.country,
    loggedInUserCountries: loggedInUser?.countries,
    isAuthenticated,
    isPaused,
    isAdmin,
    isLegalAdvisor,
    isFuneralAdvisor,
    isTombstoneAdvisor,
    isTherapist,
    isOfficiant,
    isSupplier,
    hasMultipleCountries,
    isConnectedToGlobalMarket,
    toggleFavoritesAtTop: () => dispatch(toggleFavoritesAtTop()),
    updateLoggedInUser: data => dispatch(updateLoggedInUser(data))
  };
}

export default useLoggedInUser;
