import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from 'Library/functions';

const ProductListItem = ({
  orderInfo,
  product,
  selectProduct,
  countryCode
}) => {
  const productClicked = (e) => {
    e.preventDefault();
    selectProduct(product.product_id);
  };

  let noGoList = [
    1272,
    5201,
    5204,
    14072,
    14322,
    14606
  ];

  if (orderInfo.lawyerOrder) {
    noGoList = [8004];
  }

  if (noGoList.includes(product.id)) {
    return null;
  }

  return (
    <div className='product-list-item'>

      <div className='product-list-item__image'>
        <a href='javascript.void(0)' onClick={e => productClicked(e)}>
          <img src={product?.media[0]?.sizes?.medium} alt={product?.media[0]?.alt} />
        </a>
      </div>

      <h4 className='product-list-item__title'>
        <a href='javascript.void(0)' onClick={e => productClicked(e)}>
          {product.name}
        </a>
      </h4>

      <div className='product-list-item__price'>
        <span>{formatCurrency(product.price, null, countryCode, orderInfo?.order?.currency)}</span>
      </div>
    </div>
  );
};

ProductListItem.propTypes = {
  orderInfo: PropTypes.object,
  product: PropTypes.object,
  selectProduct: PropTypes.func,
  countryCode: PropTypes.string
};

export default ProductListItem;
