import React from 'react';
import PropTypes from 'prop-types';
import UserImage from '../../UserImage';
import useInvitations from 'ReduxHooks/useInvitations';
import Rating from '../../Rating';
import UserTimeSlots from '../../UserTimeSlots';
import { useDispatch, useSelector } from 'react-redux';
import { setUserToAssign } from 'ReduxActions/orderAssigner';
import ReactSVG from 'react-svg';
import UserTitle from './UserTitle';
import UserDistance from './UserDistance';
import UserCompetenceAreas from './UserCompetenceAreas';
import UserHomeAddress from './UserHomeAddress';
import UserWorkloadDetails from './UserWorkloadDetails';
import { useTranslation } from 'react-i18next';

const UserListGroupItem = ({
  order,
  user,
  hasActiveQueue = false
}) => {
  const { addToQueue } = useInvitations();
  const { userToAssign } = useSelector(state => state.orderAssigner);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function assignDirectDisabled () {
    if (hasActiveQueue) {
      return true;
    }

    return order.user && order.user.id === user.id;
  }

  return (
    <div className='user-list-group-item'>
      <div className='user-list-group__table__body__item'>
        <div className='user-image-with-details'>
          <div className='user-image-container'>
            <UserImage
              user={user}
            />
          </div>
          <div>
            <UserTitle
              user={user}
              order={order}
            />
            <UserHomeAddress
              user={user}
            />
            <UserCompetenceAreas
              user={user}
              order={order}
            />
            <Rating
              rating={user.rating}
              numberOfReviews={user.number_of_reviews}
            />
            <button
              className='btn small'
              disabled={assignDirectDisabled()}
              role='button'
              data-cy='ua_assign_direct'
              onClick={() => dispatch(setUserToAssign(user))}
            >
              {t('Assign directly')}
            </button>
          </div>
        </div>
        <div>
          <UserDistance user={user} />
        </div>
        <div>
          <UserTimeSlots user={user} />
        </div>
        <div>
          <button
            className='btn icon'
            disabled={hasActiveQueue || userToAssign}
            role='button'
            data-cy='ua_add_to_queue'
            onClick={() => addToQueue(user)}
          >
            <ReactSVG src='/icons/icon-plus-circle.svg' />
            <span>{t('Add')}</span>
          </button>
        </div>
      </div>
      <div>
        <UserWorkloadDetails user={user} country={order.country} />
      </div>
    </div>
  );
};

UserListGroupItem.propTypes = {
  order: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  hasActiveQueue: PropTypes.bool
};

export default UserListGroupItem;
