import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'Library/functions';
import { isCreateLawyerOrderTask, isCreateTombstoneOrderTask, isInvoiceTask, isOfferTask, isTransportTask, isUpdatedOfferTask, isPaymentTask } from 'Library/Tasks';
import { If } from 'Conditionals';
import InvoiceStatus from './InvoiceStatus';
import useOrderOffers from 'ReduxHooks/useOrderOffers';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import OfferStatus from './OfferStatus';
import PaymentStatus from './PaymentStatus';
import { useTranslation } from 'react-i18next';

const TaskConfirmedAt = ({ task, order }) => {
  const { t } = useTranslation();
  const { getOffers } = useOrderOffers();
  const { loggedInUserCountry } = useLoggedInUser();

  const getTransportReceivedAtTimestamp = () => {
    const { metadata } = task.pivot;
    if (!isTransportTask(task) || !metadata) {
      return null;
    }

    if (!metadata.removal_confirmed_at) {
      return null;
    }

    return (
      <span className='task-sent-date metadata'>
        <strong>Bisatt</strong> {formatDate(metadata.removal_confirmed_at, loggedInUserCountry.code)}
      </span>
    );
  };

  const getVerificationTimestamp = () => {
    if (!task.verified) {
      return null;
    }

    return (
      <span className='task-sent-date metadata'>
        <strong>{t('Customer verified')}</strong> {formatDate(task.verified, loggedInUserCountry.code)}
      </span>
    );
  };

  const getVerbalOfferSentTimetamp = () => {
    if (!task.pivot.completed || !task.pivot.metadata || !task.pivot.metadata.verbal_offer) {
      return null;
    }

    return (
      <span className='task-sent-date metadata'>
        <strong>{t('Verbal offer sent')}</strong> {formatDate(task.pivot.metadata.verbal_offer, loggedInUserCountry.code)}
      </span>
    );
  };

  const getCaseOrderCreatedTimestamp = () => {
    if (!isCreateTombstoneOrderTask(task) && !isCreateLawyerOrderTask(task)) {
      return null;
    }

    if (!task.pivot.completed) {
      return null;
    }

    return (
      <span className='task-sent-date'>
        <strong>{t('Task created')}</strong> {formatDate(task.pivot.completed, loggedInUserCountry.code)}
      </span>
    );
  };

  const getInvoicesSentAtTimestamps = () => {
    if (!isInvoiceTask(task)) {
      return null;
    }

    return order.invoices.map(invoice => (
      <InvoiceStatus key={invoice.id} invoice={invoice} />
    ));
  };

  const getOfferDetails = () => {
    if (!isOfferTask(task) && !isUpdatedOfferTask(task)) {
      return null;
    }

    const isOrderConfirmation = isUpdatedOfferTask(task);
    const offers = getOffers(isOrderConfirmation);

    return offers.map(offer => (
      <OfferStatus
        offer={offer}
        key={offer.id}
        isOrderConfirmation={isOrderConfirmation}
      />
    ));
  };

  const getPaymentDetails = () => {
    if (!isPaymentTask(task)) {
      return null;
    }

    return order.stripePayments.map(stripePayment => (
      <PaymentStatus
        key={stripePayment.id}
        stripePayment={stripePayment}
        order={order}
      />
    ));
  };

  function showConfirmReceivedAt () {
    if (!task.pivot.confirm_received_at) {
      return false;
    }

    return !isOfferTask(task);
  }

  const label = (isInvoiceTask(task)) ? t('Opened') : t('Confirmed');

  return (
    <div className='task__confirmed'>
      <span className='task__confirmed__separator' />
      <If condition={task.pivot.email_sent_at}>
        <span className='task-sent-date'><strong>{t('Sent')}</strong> {formatDate(task.pivot.email_sent_at, loggedInUserCountry.code)}</span>
      </If>
      <If condition={showConfirmReceivedAt()}>
        <span className='task-sent-date'><strong>{label}</strong> {formatDate(task.pivot.confirm_received_at, loggedInUserCountry.code)}</span>
      </If>
      {getVerificationTimestamp()}
      {getVerbalOfferSentTimetamp()}
      {getCaseOrderCreatedTimestamp()}
      {getTransportReceivedAtTimestamp()}
      {getInvoicesSentAtTimestamps()}
      {getOfferDetails()}
      {getPaymentDetails()}
    </div>
  );
};

TaskConfirmedAt.propTypes = {
  task: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired
};

export default TaskConfirmedAt;
