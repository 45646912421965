import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'Components/Modal';
import { If } from 'Conditionals/index';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import RequiresModerationModalContent from './RequiresModerationModalContent';
import ReplyForm from './ReplyForm';
import SingleReviewStars from './SingleReviewStars';

function SingleReview ({ review, updateReply, updateRequiresModeration }) {
  const [currentReview, setCurrentReview] = useState(review);
  const [requiresModalIsOpen, setRequiresModerationModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const openRequiresModerationModal = () => {
    setRequiresModerationModalIsOpen(true);
  };

  const closeRequiresModerationModal = () => {
    setRequiresModerationModalIsOpen(false);
  };

  const dispatchReply = async (review) => {
    setIsLoading(true);

    setCurrentReview(review);

    await updateReply(review);

    setIsLoading(false);
  };

  const dispatchRequiresModeration = async (review) => {
    setIsLoading(true);

    setCurrentReview(review);

    await updateRequiresModeration(review);

    closeRequiresModerationModal();

    setIsLoading(false);
  };

  return (
    <div className={'single-review'}>
      <div className={'single-review__container'}>
        <div className={'single-review__container__header'}>
          <SingleReviewStars review={currentReview} />
          <button
            type="button"
            className={'single-review__container__header__requires-moderation-button'}
            style={{
              filter: currentReview.requires_moderation ? 'grayscale(0)' : 'grayscale(1)',
            }}
            onClick={openRequiresModerationModal}
          >
            <ReactSVG src="/icons/flag.svg" />
          </button>
        </div>

        <div className={'single-review__container__content'}>
          <span className={'single-review__container__content__title'}>{currentReview.title}</span>
          <p>
            {currentReview.review}
          </p>
        </div>

        <If condition={review.source !== 'trustpilot'}>
          <ReplyForm
            review={currentReview}
            isLoading={isLoading}
            updateReply={dispatchReply}
          />
        </If>
      </div>

      <div className={'single-review__aside'}>
        <ul>
          <li>
            <span>{t('User')}</span> {currentReview.user?.name || '-'}
          </li>
          <li>
            <span>{t('Customer')}</span> {currentReview.customer_name}
          </li>
          <li>
            <span>{t('Reference')}</span> {currentReview.order?.number || '-'}
          </li>
          <li>
            <span>{t('Date')}</span> {currentReview.review_date}</li>
          <li>
            <span>{t('Source')}</span> {currentReview.source_label}
          </li>
          <li>
            <span>{t('Market')}</span> {currentReview.market_label}
          </li>
          <li>
            <span>{t('Language')}</span> {currentReview.language_label}
          </li>
        </ul>
      </div>

      <Modal
        close={closeRequiresModerationModal}
        className="single-review__modal"
        headerTitle={t('Requires moderation')}
        isOpen={requiresModalIsOpen}
      >
        <If condition={requiresModalIsOpen}>
          <RequiresModerationModalContent
            review={currentReview}
            isLoading={isLoading}
            updateRequiresModeration={dispatchRequiresModeration}
            cancel={closeRequiresModerationModal}
          />
        </If>
      </Modal>
    </div>
  );
}

SingleReview.propTypes = {
  review: PropTypes.object,
  isLoading: PropTypes.bool,
  refreshReviews: PropTypes.func,
};

export default SingleReview;
