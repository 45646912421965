import { useEffect, useState } from 'react';
import API from '../stores/API';
import { showWarningNotice } from 'Library/notifications';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';

function useGraphs (url, defaultParams = {}) {
  const { loggedInUserCountry } = useLoggedInUser();
  const [params, setParams] = useState({ ...defaultParams, ...{ country_id: loggedInUserCountry.id } });
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getData();
  }, [params]);

  async function getData () {
    const args = Object.entries(params).map(([key, value]) => {
      return (value) ? `${key}=${value}` : null;
    }).filter(value => value).join('&');

    setIsLoading(true);
    const response = await API.GET(`${url}?${args}`);
    setIsLoading(false);

    if (response.status !== 'ok') {
      showWarningNotice(`💔 ${response.errors.error_message}`);
      return;
    }

    setData(response.data.graphData);
  }

  const setParam = (args = {}) => {
    setParams({
      ...params,
      ...args
    });
  };

  return {
    data,
    isLoading,
    params,
    setParam
  };
}

export default useGraphs;
