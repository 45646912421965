import { useDispatch, useSelector } from 'react-redux';
import {
  addUserImage,
  createUser,
  deleteUser,
  fetchActiveOrders,
  deleteUserImage,
  getUser,
  getUsers,
  persistUser,
  toggleInactive
} from '../actions/users';
import isEqual from 'lodash/isEqual';
import API from '../../stores/API';
import { showWarningNotice } from 'Library/notifications';
import { useState } from 'react';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';

function useUsers () {
  const dispatch = useDispatch();
  const { loggedInUserCountry: country } = useLoggedInUser();
  const [dropdownUsers, setDropdownUsers] = useState([]);
  const [dropdownFilters, setDropdownFilters] = useState({});
  const { user, users, pagination } = useSelector(state => state.users);

  const loadUser = async (id) => {
    await dispatch(getUser(id));

    return user;
  };

  const loadDropdownUsers = async ({ role = '', locationId = null, withArchived = false, overrideCountryId = null }) => {
    const filters = { role, locationId, withArchived };
    let uri = `/users?limit=-1&role=${role}`;

    if (overrideCountryId) {
      filters.countryId = overrideCountryId;
      uri += `&country_id=${overrideCountryId}`;
    } else if (country.id) {
      filters.countryId = country.id;
      uri += `&country_id=${country.id}`;
    }

    if (isEqual(filters, dropdownFilters)) {
      return dropdownUsers;
    }

    if (withArchived) {
      uri += '&with_archived=1';
    }

    if (locationId) {
      uri += `&location_id=${locationId}`;
    }

    setDropdownFilters(filters);

    const { status, errors, data } = await API.GET(uri);

    if (status === 'ok') {
      setDropdownUsers(data);
      return data;
    }

    setDropdownUsers([]);
    showWarningNotice(errors.error_message);

    return data;
  };

  return {
    user,
    users,
    pagination,
    dropdownUsers,
    loadUser,
    loadDropdownUsers,
    clearDropdownUsers: () => setDropdownUsers([]),
    create: (data) => dispatch(createUser(data)),
    update: (data) => dispatch(persistUser(data)),
    destroy: (id) => dispatch(deleteUser(id)),
    loadUsers: (filters) => dispatch(getUsers(filters)),
    addUserImage: (id, formData) => dispatch(addUserImage(id, formData)),
    toggleInactive: (id, deactivate = true) => dispatch(toggleInactive(id, deactivate)),
    deleteUserImage: (id) => dispatch(deleteUserImage(id)),
    fetchActiveOrders: (id) => dispatch(fetchActiveOrders(id))
  };
}

export default useUsers;
