import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'Library/functions';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';

function ActiveInvitationListItemStatus ({ invitation = null }) {
  const { t } = useTranslation();
  const { loggedInUserCountry } = useLoggedInUser();

  function getTimeRemaining () {
    const diffInMinutes = (invitation.expires - moment().diff(moment(invitation.sent), 'minutes'));

    return moment().subtract(diffInMinutes, 'minutes').toNow(true);
  }

  function getStatus () {
    if (!invitation) {
      return '-';
    }

    if (!invitation.accepted && !invitation.rejected) {
      const timeRemaining = getTimeRemaining();
      return (
        <Trans timeRemaining={timeRemaining}>
          Awaiting response {{ timeRemaining }} remaining
        </Trans>
      );
    }

    if (invitation.accepted) {
      return `${t('Accepted')} (${formatDate(invitation.accepted, loggedInUserCountry.code)})})`;
    }

    if (invitation.timedOut) {
      return t('The invitation timed out');
    }

    return `${t('Declined')} (${formatDate(invitation.rejected, loggedInUserCountry.code)})`;
  }

  return (
    <span>{getStatus()}</span>
  );
}

ActiveInvitationListItemStatus.propTypes = {
  invitation: PropTypes.object
};

export default ActiveInvitationListItemStatus;
