import useSingleOrder from './useSingleOrder';
import API from '../../stores/API';
import { set } from '../actions/order';
import { useDispatch } from 'react-redux';

function useSessions () {
  const { order } = useSingleOrder();
  const dispatch = useDispatch();

  async function createPayment (data) {
    const response = await API.POST(`/therapy_orders/${order.id}/create-payment?include=stripePayments.items,expenses.stripePaymentItem,appointments.stripePaymentItem`, data);

    dispatch(set(response.data));

    return response;
  }

  return {
    createPayment
  };
}

export default useSessions;
