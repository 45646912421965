import API from '../../stores/API';
import isEqual from 'lodash/isEqual';

export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_LOCATION_FILTERS = 'SET_LOCATION_FILTERS';

function recieveLocations (payload) {
  return {
    type: SET_LOCATIONS,
    payload
  };
}

function recieveLocationFilters (payload) {
  return {
    type: SET_LOCATION_FILTERS,
    payload
  };
}

export function resetLocations () {
  return recieveLocations([]);
}

export function getLocations (filters) {
  return async (dispatch, getState) => {
    const { locations, countries } = getState();
    let uri = '/locations?';

    filters = {
      ...{
        limit: 'all',
        countryId: null
      },
      ...filters
    };

    if (filters.limit) {
      uri += `limit=${filters.limit}`;
    }

    if (!filters.countryId && countries) {
      filters.countryId = countries.country.id;
    }

    if (filters.countryId) {
      uri += `&country_id=${filters.countryId}`;
    }

    if (locations.locations.length && isEqual(filters, locations.locationFilters)) {
      return locations.locations;
    }

    dispatch(recieveLocationFilters(filters));

    const { data } = await API.GET(uri);

    dispatch(recieveLocations(data));
    return data;
  };
}
