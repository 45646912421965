import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDate } from 'Library/functions';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { useTranslation } from 'react-i18next';

const OrderCustomerVerified = () => {
  const { t } = useTranslation();
  const { order, isTherapyOrder } = useSingleOrder();

  if (!order || !isTherapyOrder) {
    return null;
  }

  if (order.country.code === 'global') {
    return null;
  }

  return (
    <div className='order-customer-verfied'>
      <span className='icon'>
        <FontAwesomeIcon
          icon={['fal', 'badge-check']}
        />
      </span>
      {order.isVerified ? (
        <span>{t('Customer verified')} <strong>{formatDate(order.verificationDate, order.country.code)}</strong></span>
      ) : (
        <span>{t('Customer not verified')}</span>
      )}
    </div>
  );
};

export default OrderCustomerVerified;
