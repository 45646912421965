import React from 'react';
import PropTypes from 'prop-types';

function SingleReviewStars ({ review }) {
  const rating = parseInt(review.rating || '0');

  const stars = [];

  for (let i = 0; i < rating; i++) {
    stars.push(<Star rating={rating} key={`rating_${rating}_${i}`} />);
  }

  for (let i = 0; i < (5 - rating); i++) {
    stars.push(<Star rating={0} key={`rating_0_${i}`} />);
  }

  return (
    <div className={'review-stars'}>
      {stars}
    </div>
  );
}

const Star = ({ rating }) => {
  const getColorForRating  = () => {
    switch (rating) {
      case 5:
        return '#5ad45a';
      case 4:
        return '#8be04e';
      case 3:
        return '#e6d800';
      case 2:
        return '#ef9b20';
      case 1:
        return '#ea5545';
      default:
        return '#bbbdbd';
    }
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={getColorForRating()}
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="#33333305"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z"
      />
    </svg>
  );
}

SingleReviewStars.propTypes = {
  review: PropTypes.object,
};

export default SingleReviewStars;
