import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { Link } from 'react-router-dom';
import { AdminOnly } from 'Conditionals';
import { Input, Toggle } from 'FormElements';
import UserRegistryList from './UserRegistryList';
import Spinner from '../Spinner';
import LocationDropdown from '../LocationDropdown';
import UserRoleDropdown from './UserRoleDropdown';
import ListingPagination from '../ListingPagination';
import CompetenceAreaDropdown from '../CompetenceAreaDropdown';
import UserCountryDropdown from 'Components/UserCountryDropdown';

// Redux
import useUsers from 'ReduxHooks/useUsers';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';

const UserRegisty = () => {
  const { t } = useTranslation();
  const { loadUsers, pagination } = useUsers();
  const { hasMultipleCountries, loggedInUser: { country: userCountry } } = useLoggedInUser();
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedTerm = useCallback(debounce(s => updateFilters('search', s), 1000), []);
  const [filters, setFilters] = useState({
    withActivity: false,
    country: userCountry,
    location: null,
    role: null,
    competenceAreas: [],
    search: '',
    page: 1
  });
  const countryId = filters.country ? filters.country.id : null;

  const updateSearchTerm = (s = '') => {
    setSearchTerm(s);
    debouncedTerm(s);
  };

  function updateFilters (key, value) {
    const updatedFilters = { ...filters };

    if (key !== 'page') {
      updatedFilters.page = 1;
    }

    updatedFilters[key] = value;

    setFilters(updatedFilters);
  }

  function updateCountry (countries) {
    setFilters({
      ...filters,
      page: 1,
      location: null,
      country: countries.length ? countries[0] : null
    });
  }

  function paginate (direction = 'next') {
    if (pagination.current_page === 1 && direction === 'previous') {
      return;
    }

    if (pagination.current_page === pagination.total_pages && direction === 'next') {
      return;
    }

    if (direction === 'next') {
      updateFilters('page', filters.page + 1);
      return;
    }

    updateFilters('page', filters.page - 1);
  }

  useEffect(() => {
    fetchUsers();
  }, [filters]);

  async function fetchUsers () {
    setIsLoading(true);
    await loadUsers(filters);
    setIsLoading(false);
  }

  return (
    <div className='registry'>
      <h1>{t('User register')}</h1>
      <div className='page-filters'>
        <AdminOnly>
          <div className='header-with-filters__filters__filter'>
            <Link
              to='/anvandare/skapa'
              className='btn'
            >{t('Create new')}
            </Link>
          </div>
        </AdminOnly>
        <div className='header-with-filters__filters__filter'>
          <Input
            placeholder={t('Search')}
            value={searchTerm}
            data-cy='user-search-field'
            disabled={isLoading}
            onChange={search => updateSearchTerm(search)}
          />
        </div>
        <div className='header-with-filters__filters__filter'>
          <CompetenceAreaDropdown
            isMulti
            countryId={countryId}
            type={null}
            onChange={role => updateFilters('competenceAreas', role)}
          />
        </div>
        <div className='header-with-filters__filters__filter'>
          <UserRoleDropdown
            onChange={role => updateFilters('role', role)}
          />
        </div>
        {hasMultipleCountries && (
          <div className='header-with-filters__filters__filter'>
            <UserCountryDropdown
              selected={filters.country}
              onChange={updateCountry}
              isClearable
            />
          </div>
        )}
        <div className='header-with-filters__filters__filter'>
          <LocationDropdown
            countryId={countryId}
            value={filters.location}
            onChange={location => updateFilters('location', location ? location.value : null)}
          />
        </div>
        <AdminOnly>
          <div className='header-with-filters__filters__filter'>
            <Toggle
              label={t('Include activity')}
              defaultChecked={!!filters.withActivity}
              onChange={value => updateFilters('withActivity', value)}
            />
          </div>
        </AdminOnly>
      </div>

      {isLoading
        ? (
          <Spinner />
          )
        : (
          <UserRegistryList showLastActive={filters.withActivity} />
          )}

      <ListingPagination
        pagination={pagination}
        switchPage={direction => paginate(direction)}
      />
    </div>
  );
};

export default UserRegisty;
