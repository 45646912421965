import { formatDate, readableDiffSince } from './functions';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { sprintf } from 'sprintf-js';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';

export function renderAddress (supplier = null, addressType = 'visit') {
  const { t } = useTranslation();

  if (!supplier) {
    return '';
  }

  const address = supplier.addresses.find(({ type, address_1: addressOne }) => type === addressType && addressOne);
  if (!address) {
    return t('No address');
  }

  const { address_1: addressOne, postcode } = address;

  return [addressOne.trim(), postcode.trim()].filter(item => item).join(', ');
}

export function renderTaskInformation (task = null, countryCode = 'se') {
  const { t } = useTranslation();
  const { loggedInUserCountry: country } = useLoggedInUser();

  if (!task) {
    return null;
  }

  const { email_sent_at: sentAt, confirm_received_at: receivedAt } = task.pivot;

  if (receivedAt) {
    return sprintf(t('— Accepted on %s'), formatDate(receivedAt, country.code));
  }

  if (!sentAt) {
    return sprintf(t('— The task %s has not been sent'), task.name);
  }

  const daysAgo = moment().diff(moment(sentAt), 'days');
  if (daysAgo >= 1) {
    return t('— has not gotten back within 24 hours');
  }
  return sprintf(t('— Was sent %s ago'), readableDiffSince(sentAt, countryCode));
}
