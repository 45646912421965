import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { If } from 'Conditionals';
import { TableRow, TableColumn } from 'Layout';
import {
  renderOrderNumberColumn,
  renderOrderLinkColumn,
  renderStatusColumn,
  renderActionColumn,
  renderLocationColumn,
  renderOrderCaseTypeColumn,
  renderCustomerMeetingColumn,
  renderNextMeetingColumn,
  renderFavoriteColumn,
  renderMarketColumn
} from 'Library/OrderListingColumns';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';

function OrderTableRow (props) {
  const { t } = useTranslation();
  const { hasMultipleCountries } = useLoggedInUser();

  const {
    order,
    isTombstoneOrder = false,
    isFuneralOrder = false,
    isLawyerOrder = false,
    isTherapyOrder = false,
    isCeremonyOrder = false
  } = props;

  return (
    <TableRow className={`row-${order.status}`}>
      <TableColumn
        render={() => renderFavoriteColumn(props)}
        className='col-order-favorite'
      />
      {hasMultipleCountries && (
        <TableColumn
          render={() => renderMarketColumn(props)}
          className='col-order-market'
        />
      )}
      <TableColumn
        render={() => renderOrderNumberColumn(props)}
        className='col-order-number'
      />
      <If condition={!isTombstoneOrder}>
        <TableColumn
          render={() => renderLocationColumn(order, isFuneralOrder)}
          className='col-location'
          label={t('Location')}
        />
        <TableColumn
          render={() => renderOrderLinkColumn(props)}
          className='col-order-link'
          label={(isLawyerOrder || isTherapyOrder || isCeremonyOrder) ? t('Kund/Avliden') : t('Avliden')}
        />
        <If condition={isFuneralOrder}>
          <TableColumn
            render={() => renderCustomerMeetingColumn(order)}
            className='col-order-meeting'
            label={t('Customer meeting')}
          />
        </If>
        <If condition={isLawyerOrder || isTherapyOrder}>
          <TableColumn
            render={() => renderOrderCaseTypeColumn(order)}
            className='col-order-case-type'
            label={t('Type')}
          />
        </If>
      </If>
      <If condition={isTombstoneOrder}>
        <TableColumn
          render={() => renderOrderLinkColumn(props)}
          className='col-order-link'
          label={t('Deceased')}
        />
        <TableColumn
          render={() => order?.customer?.name}
          className='col-customer-name'
          label={t('Customer')}
        />
      </If>
      <If condition={isTherapyOrder}>
        <TableColumn
          render={() => renderNextMeetingColumn(props)}
          className='col-order-meeting'
          label={t('Customer meeting')}
        />
      </If>
      <TableColumn
        render={() => renderStatusColumn(props)}
        className='col-order-status'
      />
      <TableColumn
        render={() => renderActionColumn(props)}
        className='col-order-action'
      />
    </TableRow>
  );
}

OrderTableRow.propTypes = {
  order: PropTypes.object.isRequired,
  isLawyerOrder: PropTypes.bool,
  isTombstoneOrder: PropTypes.bool,
  isFuneralOrder: PropTypes.bool,
  isTherapyOrder: PropTypes.bool,
  isCeremonyOrder: PropTypes.bool
};

export default OrderTableRow;
