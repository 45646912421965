import API from '../../stores/API';
import { showWarningNotice } from 'Library/notifications';

export const SET_REVIEW = 'SET_REVIEW';
export const SET_REVIEWS = 'SET_REVIEWS';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_IS_LOADING = 'SET_IS_LOADING';

export function receiveReview (payload) {
  return {
    type: SET_REVIEW,
    payload
  };
}

function receiveReviews (payload) {
  return {
    type: SET_REVIEWS,
    payload
  };
}

function receiveReviewsPagination (payload) {
  return {
    type: SET_PAGINATION,
    payload
  };
}

export function setIsLoading (payload) {
  return {
    type: SET_IS_LOADING,
    payload
  };
}

export function getReview (id) {
  return async (dispatch) => {
    dispatch(receiveReview(null));

    const { errors, data } = await API.GET(`/reviews/${id}`);

    if (errors && errors.code === 404) {
      alert('Review not found!'); // This review have to do for now...
      return;
    }

    dispatch(receiveReview(data));
  };
}

export function getReviews ({ countryId, userId, page = 1 }) {
  return async (dispatch) => {
    const { data, status, errors, meta = {} } = await API.GET(
      `/reviews?country_id=${countryId}&page=${page}&user_id=${userId}`
    );

    if (status !== 'ok') {
      showWarningNotice(`💔 ${errors.error_message}`);
      return;
    }

    dispatch(receiveReviewsPagination(meta.pagination || null));
    dispatch(receiveReviews(data));
  };
}

export function persistReply () {
  return async (dispatch, getState) => {
    const { reviews } = getState();
    const review = { ...reviews.review };

    const response = await API.PUT(`/reviews/${review.id}/reply`, {
      reply: review.reply
    });

    if (response.status !== 'ok') {
      showWarningNotice(response.errors.error_message);

      return response;
    }

    parseResponseAndUpdateReview(response, dispatch);

    return response;
  };
}

export function persistRequiresModeration () {
  return async (dispatch, getState) => {
    const { reviews } = getState();
    const review = { ...reviews.review };

    const response = await API.PUT(`/reviews/${review.id}/requires-moderation`, {
      requires_moderation: review.requires_moderation,
      requires_moderation_description: review.requires_moderation_description
    });

    if (response.status !== 'ok') {
      showWarningNotice(response.errors.error_message);

      return response;
    }

    parseResponseAndUpdateReview(response, dispatch);

    return response;
  };
}

export function updateReview (data) {
  return async (dispatch) => {
    dispatch(receiveReview(data));
  };
}

function parseResponseAndUpdateReview (response, dispatch) {
  const { data, status, errors } = response;

  if (status !== 'ok') {
    showWarningNotice(errors.error_message);

    return;
  }

  dispatch(receiveReview(data));
}
