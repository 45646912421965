import React, { useEffect, useState } from 'react';
import useCompetenceAreas from 'ReduxHooks/useCompetenceAreas';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import { HeaderWithFilters } from 'Layout';
import { Link } from 'react-router-dom';
import Spinner from '../Spinner';
import { ALL_TYPES, TYPE_FUNERAL, TYPE_LEGAL, TYPE_THERAPY, TYPE_CEREMONY } from '../../Redux/actions/competenceAreas';
import { Select } from '../FormElements';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'Library/functions';
import UserCountryDropdown from 'Components/UserCountryDropdown';

function CompetenceAreasArchive () {
  const { t } = useTranslation();
  const [type, setType] = useState(ALL_TYPES);
  const [isLoading, setIsLoading] = useState(false);
  const { fetchCompetenceAreas } = useCompetenceAreas();
  const [competenceAreas, setCompetenceAreas] = useState([]);
  const { hasMultipleCountries, loggedInUser: { country: userCountry } } = useLoggedInUser();
  const [country, setCountry] = useState(userCountry);

  useEffect(() => {
    init();
  }, [country]);

  async function init () {
    setIsLoading(true);
    await fetchCompetenceAreas({ countryId: country ? country.id : null }).then(setCompetenceAreas);
    setIsLoading(false);
  }

  function renderType (type) {
    switch (type) {
      case TYPE_FUNERAL:
        return t('Funeral');
      case TYPE_LEGAL:
        return t('Legal');
      case TYPE_THERAPY:
        return t('Therapy');
      case TYPE_CEREMONY:
        return t('Ceremony');
      default:
        return ' - ';
    }
  }

  function getAreasOfType (type = TYPE_LEGAL) {
    if (!competenceAreas) {
      return [];
    }

    if (type === ALL_TYPES) {
      return competenceAreas;
    }

    return competenceAreas.filter(area => area.type === type);
  }

  return (
    <div className='registry'>
      <HeaderWithFilters header={t('Skills')}>
        <div className='header-with-filters__filters__filter'>
          <Link
            to='/kompetenser/skapa'
            className='btn'
          >{t('Create new')}
          </Link>
        </div>

        {hasMultipleCountries && (
          <div className='header-with-filters__filters__filter'>
            <UserCountryDropdown
              selected={country}
              onChange={countries => setCountry(countries[0])}
              isClearable
            />
          </div>
        )}

        <div className='header-with-filters__filters__filter'>
          <Select
            value={type}
            className='col-6'
            options={{
              [ALL_TYPES]: ' -- ' + t('Choose typ') + ' --',
              [TYPE_FUNERAL]: t('Funeral'),
              [TYPE_LEGAL]: t('Legal'),
              [TYPE_THERAPY]: t('Therapy'),
              [TYPE_CEREMONY]: t('Ceremony')
            }}
            onChange={value => setType(value)}
          />
        </div>
      </HeaderWithFilters>

      {isLoading
        ? (
          <Spinner />
          )
        : (
          <div className='registry__registry-list'>
            <table className='registry__registry-list__table'>
              <thead>
                <tr>
                  <th>{t('Name')}</th>
                  <th>{t('Type')}</th>
                  <th>{t('Time per order')}</th>
                  <th>{t('Sort value')}</th>
                  <th>{t('Created')}</th>
                </tr>
              </thead>
              <tbody>
                {getAreasOfType(type)
                  .sort((a, b) => (b.sort_value - a.sort_value || a.name.localeCompare(b.name)))
                  .map(({ id, name, type, duration, created_at: createdAt, sort_value: sortValue }) => (
                    <tr key={id}>
                      <td><Link to={`/kompetenser/${id}`}>{name}</Link></td>
                      <td>{renderType(type)}</td>
                      <td>{duration || ' - '}</td>
                      <td>{sortValue}</td>
                      <td>{formatDate(createdAt, userCountry.code)}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          )}
    </div>
  );
}

export default CompetenceAreasArchive;
