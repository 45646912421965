import { SET_TASK, SET_TASK_COUNTRY } from '../actions/tasks';

const initialState = {
  task: null,
  taskCountry: null
};

export default function tasks (state = initialState, { type, payload }) {
  switch (type) {
    case SET_TASK: {
      return {
        ...state,
        task: payload
      };
    }
    case SET_TASK_COUNTRY: {
      return {
        ...state,
        taskCountry: payload
      };
    }

    default:
      return state;
  }
}
