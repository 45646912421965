import React from 'react';
import useOrderEvents from '../../Redux/hooks/useOrderEvents';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import Event from '../Event';

function EventList () {
  const { events = [] } = useOrderEvents();
  const { getCountryCode } = useSingleOrder();

  return (
    <div className='event-list'>
      <ul className='events'>
        {events && events.map(event => (
          <Event
            key={event.id}
            event={event}
            countryCode={getCountryCode()}
          />
        ))}
      </ul>
    </div>
  );
}

export default EventList;
