import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import { getNotificationText, getUrl, getSupportedNotices } from 'Library/NotificationHelper';

const Notice = ({ notice, markRead, toggleList, deleteNotice }) => {
  const { t } = useTranslation();
  const { loggedInUserCountry: country } = useLoggedInUser();
  const [showDelete, setShowDelete] = useState(false);

  const className = classNames('notice', {
    unread: !notice.acknowledged_at,
    show_delete: showDelete
  });

  // If the notice is not supported, return null
  if (!getSupportedNotices().includes(notice.status)) {
    return null;
  }

  return (
    <div
      className={className}
      onMouseOver={() => setShowDelete(true)}
      onMouseOut={() => setShowDelete(false)}
      onClick={() => {
        if (!notice.acknowledged_at) {
          markRead(notice.id);
        }
      }}
    >
      <div className='notice__wrapper'>
        <div className='notice__header'>
          <span dangerouslySetInnerHTML={{ __html: getNotificationText(notice, country) }} />
          {' '}
          <Link className='notice__header__order-number' to={getUrl(notice)} onClick={() => toggleList(false)}>
            {t('Read more')}
          </Link>
        </div>
      </div>
      <button
        className='notice__delete'
        onClick={() => deleteNotice(notice.id)}
      >
        <ReactSVG
          src='/icons/icon-trash.svg'
          className='notice__delete__svg'
        />
      </button>
    </div>
  );
};

Notice.propTypes = {
  notice: PropTypes.object.isRequired,
  markRead: PropTypes.func.isRequired,
  toggleList: PropTypes.func.isRequired,
  deleteNotice: PropTypes.func.isRequired
};

export default withRouter(Notice);
