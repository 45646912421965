import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'Library/functions';
import { If } from 'Conditionals';
import { useTranslation } from 'react-i18next';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import useOfferActions from 'Hooks/useOfferActions';
import LavendlaModal from 'Components/Modal';
import Select from 'react-select';
import { LoadingButton } from 'FormElements';
import Notice from 'Layout/Notice';

function OfferStatus ({ offer, isOrderConfirmation = false }) {
  const { t } = useTranslation();
  const { loggedInUserCountry } = useLoggedInUser();
  const [showDeadlineModal, setShowDeadlineModal] = useState(false);
  const [newDeadline, setNewDeadline] = useState('48');
  const [deadlineDate, setDeadlineDate] = useState(offer.payment_deadline);
  const [missedDeadlineDate, setMissedDeadlineDate] = useState(offer.has_missed_payment_at);
  const [isUpdatingDeadline, setIsUpdatingDeadline] = useState(false);
  const { refundPayment, generateReceipt, updateDeadline } = useOfferActions(offer);
  const isTherapyOffer = offer.resource_type === 'App\\TherapyOrder';
  const showOfferText = isOrderConfirmation ? t('Show order confirmation') : isTherapyOffer ? t('Show payment') : t('Show offer');

  const getReceipt = async () => {
    await generateReceipt();
  };

  const doRefund = async () => {
    if (!window.confirm(t('Are you sure you want to refund this payment?'))) {
      return;
    }

    await refundPayment();
  };

  const openDeadlineMOdal = () => {
    setShowDeadlineModal(true);
  };

  const deadlines = [
    { value: '1', label: t('1 hour') },
    { value: '3', label: t('3 hours') },
    { value: '5', label: t('5 hours') },
    { value: '10', label: t('10 hours') },
    { value: '24', label: t('1 day') },
    { value: '48', label: t('2 days') },
    { value: '72', label: t('3 days') },
    { value: '96', label: t('4 days') }
  ];

  const getDeadlineSelectValue = (value) => {
    return deadlines.find(deadline => deadline.value === value);
  };

  const changeDeadline = async () => {
    setIsUpdatingDeadline(true);
    const { success, newDate } = await updateDeadline(newDeadline ? newDeadline.value : 48);
    setIsUpdatingDeadline(false);

    if (success) {
      setDeadlineDate(newDate);
      setMissedDeadlineDate(null);
      setShowDeadlineModal(false);
    }
  };

  return (
    <div
      className='invoice-status'
      id='deadline-modal-container'
    >
      <span className='task-sent-date invoice-status__field created'>
        <strong>{isTherapyOffer ? t('Payment') : t('Offer')} #{offer.id}</strong>
        &nbsp;-&nbsp;
        <a href={`${offer.link}/no-track`} target='_blank' rel='noopener noreferrer'>{showOfferText}</a>
        <br />
        <strong>{t('Created')}</strong> {formatDate(offer.created_at, loggedInUserCountry.code)}
        <If condition={offer.viewed_at}>
          <br />
          <strong>{t('Viewed')}</strong> {formatDate(offer.viewed_at, loggedInUserCountry.code)}
        </If>
        <If condition={isTherapyOffer && deadlineDate}>
          <br />
          <strong>{t('Payment deadline')}</strong> {formatDate(deadlineDate, loggedInUserCountry.code)}
          &nbsp;-&nbsp;
          <a onClick={() => openDeadlineMOdal()}>{t('Change')}</a>
          <If condition={missedDeadlineDate}>
            <If condition={missedDeadlineDate}>
              <br />
              <strong>{t('Missed deadline')}</strong> {formatDate(missedDeadlineDate, loggedInUserCountry.code)}
            </If>
          </If>
        </If>
        <If condition={offer.confirmed_at}>
          <br />
          <strong>{isTherapyOffer ? t('Paid') : t('Confirmed')}</strong> {formatDate(offer.confirmed_at, loggedInUserCountry.code)}
          <If condition={isTherapyOffer}>
            &nbsp;-&nbsp;
            <a onClick={() => getReceipt()} className='receipt'>{t('Receipt')}</a>
            <If condition={!offer.refunded_at}>
            &nbsp;
              <a onClick={() => doRefund()} className='refund-payment'>{t('Refund payment')}</a>
            </If>
          </If>
        </If>
        <If condition={offer.verified}>
          <br />
          <strong>{t('Customer verified')}</strong> {formatDate(offer.verified, loggedInUserCountry.code)}
        </If>
        <If condition={offer.refunded_at}>
          <br />
          <strong>{t('Payment refunded')}</strong> {formatDate(offer.refunded_at, loggedInUserCountry.code)}
        </If>
      </span>
      <LavendlaModal
        className='category-modal'
        close={() => setShowDeadlineModal(false)}
        headerTitle={t('Change deadline')}
        isOpen={showDeadlineModal}
        parentSelector={() => document.querySelector('#deadline-modal-container')}
      >
        <div className='form-group'>
          <label htmlFor='payment_deadline'>{t('Payment deadline')}</label>
          <Select
            value={getDeadlineSelectValue(newDeadline)}
            onChange={paymentDeadline => setNewDeadline(paymentDeadline)}
            className='select'
            options={deadlines}
            placeholder={t('Select offer deadline')}
          />
        </div>
        <br />
        <Notice>
          <p>{t('The deadline will be calculated from the current time')}</p>
        </Notice>
        <br />
        <LoadingButton
          onClick={() => changeDeadline()}
          text={t('Update')}
          isLoading={isUpdatingDeadline}
        />
      </LavendlaModal>
    </div>
  );
}

OfferStatus.propTypes = {
  offer: PropTypes.object.isRequired,
  isOrderConfirmation: PropTypes.bool
};

export default OfferStatus;
