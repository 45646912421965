import React from 'react';
import UserToAssign from 'Components/OrderCreator/UserToAssign';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function UsersList ({ users = [], setUser, country }) {
  const { t } = useTranslation();

  if (!users.length) {
    return null;
  }

  return (
    <div className='user-list-group__table'>
      <div className='user-list-group__table__header'>
        <div>{users.length} {t('advisors')}</div>
        <div>{t('Distance from customer')}</div>
        <div>{t('Availability')}</div>
        <div />
      </div>
      <div className='user-list-group__table__body'>
        {users.map(user => (
          <UserToAssign
            key={user.id}
            user={user}
            setUser={setUser}
            country={country}
          />
        ))}
      </div>
    </div>
  );
}

UsersList.propTypes = {
  users: PropTypes.array.isRequired,
  setUser: PropTypes.func.isRequired,
  country: PropTypes.object.isRequired
};

export default UsersList;
