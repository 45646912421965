import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/sv';
import appointmentsStore from '../../stores/appointments';
import Header from './Header';
import Spinner from '../Spinner';
import List from './List';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';

const MiniCalendar = () => {
  const dateFormat = 'YYYY-MM-DD';
  const { loggedInUser, loggedInUserCountry: country } = useLoggedInUser();
  const [loading, setLoading] = useState(true);
  const [appointments, setAppointments] = useState([]);

  useEffect(_ => {
    async function getAppointments () {
      const date = moment();
      const formatedDate = date.format(dateFormat);
      const type = '';

      const params = {
        type,
        user_id: loggedInUser.id,
        limit: 'all',
        start_date: formatedDate,
        end_date: formatedDate,
        with_archived: 1,
        supplier_user_id: null,
        country_id: (country) ? country.id : null
      };

      setLoading(true);
      const response = await appointmentsStore.list(params);
      setLoading(false);

      if (response.status === 'ok') {
        setAppointments(response.data);
      }
    }

    getAppointments();
  }, [loggedInUser.id]);

  return (
    <div className='mini-calendar__container'>
      <Header country={country} />
      {loading ? <Spinner /> : <List appointments={appointments} />}
    </div>
  );
};

export default MiniCalendar;
