import { parse, isValid, format } from 'date-fns';
import { sv, nb, fi, enGB } from 'date-fns/locale';
import { getOrderFuneralPlace } from 'Library/OrderHelperFuntions';
import { getTranslatedTerm } from 'Library/translationHelpers';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import useOrderSuppliers from 'Hooks/useOrderSuppliers';
import useAppointments from 'Hooks/useAppointments';
import useTextParserProducts from 'Hooks/useTextParserProducts';
import useOrderAttendees from 'ReduxHooks/useOrderAttendees';
import { getFuneralType, getFuneralDivineEconomy } from 'Library/functions';
import { legacyShortcodeDict } from 'Library/Tasks';
import { useTranslation } from 'react-i18next';

const booleans = [
  'deceased.estate_agent_evaluation',
  'deceased.estate_agent_sale',
  'entombment.participation',
  'anhorigaNarvarar',
  'bartacke',
  'funeral.canope'
];

export default function useTextParser () {
  const { t } = useTranslation();
  const { getNumAttendees } = useOrderAttendees();
  const { order, orderCountry, isFuneralOrder } = useSingleOrder();
  const { getSuppliersOfType } = useOrderSuppliers();
  const { getAppointmentOfType, maybeGetTranslatedTitle } = useAppointments();
  const {
    getProductInTerms,
    getFlowersList,
    getHeadstone,
    hasProgram,
    hasHost,
    getFeeForCivicOfficial
  } = useTextParserProducts();
  const countryCode = orderCountry ? orderCountry.code : 'se';
  const locale = {
    se: sv,
    dk: sv, // Fix for DK as date-fns does not format danish dates correctly
    no: nb,
    gb: enGB,
    fi
  };

  function getParsedString (string) {
    // first, convert original shortcodes to the new ones
    string = string.replace(/{{/g, '[');
    string = string.replace(/}}/g, ']');
    return string.replace(/\[([\w.])+\]/g, key => {
      key = key.replace(/[[\]']+/g, '');
      const shortcode = getShortcode(key);

      if (shortcode === '') {
        return '';
      }

      if (typeof shortcode === 'boolean') {
        return (shortcode) ? t('Yes') : t('No');
      }

      if (typeof shortcode === 'number' && booleans.includes(key)) {
        return (parseInt(shortcode) === 1) ? t('Yes') : t('No');
      }

      return shortcode;
    });
  }

  function getShortcode (key, defaultValue = '') {
    key = maybeConvertLegacyKey(key);
    const customValue = getCustomValue(key);

    if (customValue !== null) {
      return customValue ?? '';
    }

    const [keyOne, keyTwo, keyThree] = key.split('.');

    if (keyOne === 'suppliers') {
      return getSuppliersOfTypeContactInformation(keyTwo) ?? '';
    }

    if ((keyOne === 'appointments' || keyOne === 'appointment') && keyThree !== 'place') {
      const [field, format] = getAppointmentArgs(keyThree);
      return getAppointmentDate(keyTwo, field, format) ?? '';
    }

    if (keyOne === 'order' && typeof order[keyTwo] !== 'undefined') {
      return order[keyTwo] ?? '';
    }

    if (typeof order[keyOne] !== 'undefined' && typeof order[keyOne][keyTwo] !== 'undefined') {
      return order[keyOne][keyTwo] ?? '';
    }

    return defaultValue;
  }

  function getCustomValue (key) {
    const { user } = order;
    const ceremonyDateFormat = order.class === 'App\\Order' ? 'P' : 'P p';

    const customDict = {
      'deceased.date': getDeceasedDate(),
      'deceased.obituary_creator_url': '[obituary-creator-url]',
      'deceased.last_will': order.deceased.last_will,

      'order.company_address': '',
      'order.location': (order.location) ? order.location.name : '',
      'order.funeral_date': getCeremonyDate('start_date', ceremonyDateFormat),
      'order.user': (user) ? `${user.name}, ${user.email}, ${user.tel}` : '',

      'invoice_options.is_installment': (order.invoice_options && order.invoice_options.is_installment) ? t('Yes') : t('No'),

      'funeral.ceremony_place': getOrderFuneralPlace(order) || '',
      'funeral.num_representatives': getSuppliersOfType('representant').length,
      'funeral.officiant': getCivicOfficial(),
      'funeral.type': getFuneralType(order?.funeral?.type),
      'funeral.divine_economy': getFuneralDivineEconomy(order?.funeral?.divine_economy),

      'appointments.customer_meeting.place': getAppointmentSupplier(),

      'entombment.entombment': getEntombment(),
      'entombment.type': getEntombmentType(),
      'entombment.place': getEntombmentPlace(order.class),
      'entombment.graveyard': getEntombmentGraveyard(order.class),
      'entombment.urn_collection': order?.funeral?.urn_collection,

      'memorial.attendees': '[anmalda-till-minnesstund]',

      'memorial.attendees_no_name': '[memorial-attendees-no-name]',
      'customer.relation_to_deceased': getRelation(),

      'memorial.num_attendees': getNumAttendees(),

      'memorial_page.lavendla_post_url': '[memoral-page-link]',

      'memorial_page.creator_link': '[memorial-page-creator-url]',

      'memorial_page.email_sharelink': '[email-sharelink-obituary]',

      'appointments.customer_meeting.verification_link': '[verification-link]',

      'appointments.customer_meeting.meet_link': getMeetUrl(order),

      'appointments.customer_meeting.all_meetings_list': getAllCustomerMeetings(),

      'products.flowers': '[flowers]',
      'products.coffin': getProductInTerms(getTranslatedTerm('coffins', order.country.code)),
      'products.urn': getProductInTerms(getTranslatedTerm('urns', order.country.code)),
      'products.flowers_list': getFlowersList(),
      'products.tombstone': getHeadstone(),
      'products.has_programme': hasProgram(),
      'products.has_host': hasHost(),
      'products.official_fee': getFeeForCivicOfficial(),
      'offer.payment_deadline': '[payment-deadline]'
    };

    return (typeof customDict[key] === 'undefined') ? null : customDict[key];
  }

  function getAppointmentArgs (field) {
    switch (field) {
      case 'datetime':
        return ['start_date', 'P p'];
      case 'start_date':
        return ['start_date', 'P'];
      case 'start_time':
        return ['start_date', 'p'];
      case 'end_date':
        return ['end_date', 'P'];
      case 'end_time':
        return ['end_date', 'p'];
    }
  }

  function getAllCustomerMeetings () {
    if (!order.appointments.length) {
      return '';
    }
    let meetings = `
    <table>
      <thead>
        <tr>
          <th>${t('Date and time')}</th>
          <th>${t('Meeting type')}</th>
          <th>${t('Place')}</th>
        </tr>
      </thead>
      <tbody>
      `;
    order.appointments.filter((appointment) => appointment.type === 'customer_meeting').map((appointment) => (
      meetings += `
        <tr>
          <td>${getParsedDate(appointment.start_date, 'P p')}</td>
          <td>${maybeGetTranslatedTitle(appointment.title)}</td>
          <td>${appointment.supplier?.name || ''}</td>
        </tr>`
    ));
    return meetings + '</tbody></table>';
  }

  function getMeetUrl (order) {
    if (!order.meet_url) {
      return '';
    }

    return 'https://meet.lavendla.com/' + order.meet_url;
  }

  function getDeceasedDate () {
    const { deceased } = order;
    if (typeof deceased === 'undefined') {
      return '';
    }

    const { date } = deceased;
    if (typeof date === 'undefined') {
      return '';
    }

    return getParsedDate(date, 'P', 'yyyy-MM-dd');
  }

  function getEntombment () {
    if (typeof order.entombment === 'undefined') {
      return '';
    }

    const { entombment } = order.entombment;
    const dict = {
      direktkremation: t('Direct Cremation'),
      akt_med_urna: t('Funeral with urn'),
      kremation: t('Cremation after ceremony'),
      jordbegravning: t('Burial'),
      kistgrav: t('Coffin grave'),
      minneslund: t('Memorial grove'),
      askgravlund: t('Ash grove'),
      askgravplats: t('Ash grave'),
      urn: t('Urn'),
      kolumbarium: t('Columbarium'),
      spridning_till_sjoss: t('Spread the ashes in the ocean')
    };

    return (typeof dict[entombment] === 'undefined') ? '' : dict[entombment];
  }

  function getRelation () {
    if (typeof order.customer.relation_to_deceased === 'undefined') {
      return '';
    }

    const relation = order.customer.relation_to_deceased;

    const dict = {
      'maka/make': t('Husband/Wife'),
      barn: t('Child'),
      annat: t('Other'),
      sambo: t('Partner'),
      förälder: t('Parent')
    };

    return (typeof dict[relation] === 'undefined') ? '' : dict[relation];
  }

  function getEntombmentType () {
    if (typeof order.entombment === 'undefined') {
      return '';
    }

    const { type } = order.entombment;

    return getFuneralType(type);
  }

  function getEntombmentPlace (orderType = 'App\\Order') {
    if (typeof order.entombment === 'undefined') {
      return '';
    }

    if (orderType === 'App\\TombstoneOrder' && typeof order.entombment !== 'undefined') {
      return order.entombment.place;
    }

    if (orderType === 'App\\Order' && typeof order.funeral !== 'undefined') {
      return order.funeral.graveyard;
    }

    return '';
  }

  function getEntombmentGraveyard (orderType = 'App\\Order') {
    if (typeof order.entombment === 'undefined') {
      return '';
    }

    if (orderType === 'App\\TombstoneOrder' && typeof order.entombment !== 'undefined') {
      return order.entombment.graveyard;
    }

    if (orderType === 'App\\Order' && typeof order.funeral !== 'undefined') {
      return order.funeral.grave_place;
    }

    return '';
  }

  function getCeremonyDate (field = 'start_date', dateFormat = 'P p') {
    if (isFuneralOrder) {
      return getAppointmentDate('ceremony', field, dateFormat);
    }

    if (!order.order) {
      return t('This order is not connected to a funeral order');
    }

    const ceremony = order.order.appointments.find(({ type }) => type === 'ceremony');

    if (!ceremony) {
      return t('Ceremony date not set');
    }

    return getParsedDate(ceremony[field], dateFormat);
  }

  function getAppointmentDate (type = 'ceremony', field = 'start_date', dateFormat = 'p') {
    const appointment = getAppointmentOfType(type);
    if (!appointment) {
      return '';
    }

    return getParsedDate(appointment[field], dateFormat);
  }

  function getParsedDate (date, dateFormat = 'P p', parseFormat = 'yyyy-MM-dd HH:mm:ss') {
    const validDate = isValid(parse(date, parseFormat, new Date())) ? parse(date, parseFormat, new Date()) : null;
    return validDate ? format(validDate, dateFormat, { locale: locale[countryCode] }) : '';
  }

  function getAppointmentSupplier (type = 'customer_meeting') {
    const appointment = order.appointments.find(appointment => appointment.type === type);

    if (!appointment?.supplier?.name) {
      return '';
    }

    return appointment.supplier.name;
  }

  function getSuppliersOfTypeContactInformation (type) {
    const suppliers = getSuppliersOfType(type);

    return suppliers.map(({ name, phone }) => `${name} - ${phone}`).join(', ');
  }

  function getCivicOfficial () {
    if (order?.funeral?.divine_economy === 'borgerlig') {
      return getSuppliersOfTypeContactInformation('officiant');
    }

    return order.funeral && order.funeral.officiant ? order.funeral.officiant : '';
  }

  function maybeConvertLegacyKey (key) {
    const dict = legacyShortcodeDict;

    return (typeof dict[key] === 'undefined') ? key : dict[key];
  }

  return {
    getParsedString
  };
}
