import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sv, da, enGB } from 'date-fns/locale';
import { format } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import { Language } from 'Library/languages';
import DatePicker, { registerLocale } from 'react-datepicker';
import ReactSelect from 'react-select';
import { Notice } from 'Layout';
import { Input, LoadingButton, Textarea } from 'FormElements';

// Redux
import useUser from 'ReduxHooks/useUser';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';

const ReviewsTab = () => {
  const { t } = useTranslation();
  const { user, user: { country: userCountry }, updateField } = useUser();
  const userLanguage = Language.getLanguageFromCountryCode(userCountry.code);
  const { isAdmin } = useLoggedInUser();
  let { reviews } = user;

  registerLocale('se', sv);
  registerLocale('dk', da);
  registerLocale('en-GB', enGB);

  // Show only reviews that have source custom
  reviews = reviews.filter(({ source }) => source === 'custom');

  const addRow = () => {
    let oldRows = reviews;

    if (!oldRows) {
      oldRows = [];
    }

    updateField('reviews', [...oldRows, { title: '', review: '', source: 'custom', rating: null, id: 'new' + Math.random(), language: userLanguage }]);
  };

  const canAddMore = () => {
    if (isAdmin) {
      return true;
    }

    return reviews.length < 5;
  };

  const removeRow = id => updateField('reviews', reviews.map((review) => review.id === id ? { ...review, deleted: true } : review));

  const updateReview = (id, field, value) => updateField('reviews', reviews.map((review) => review.id === id ? { ...review, [field]: value } : review));

  return (
    <>
      <Notice>
        <h4>{t('Here you can add reviews from other sources.')}</h4>
        <p>{t("By uploading reviews here, you certify that you have the customer's permission and that the customer review is not owned by any other platform.")}</p>
        <br />
        <p>{t('You can add up to 5 reviews.')}</p>
      </Notice>
      <div className='review__list'>
        <AnimatePresence initial={false}>
          {reviews && reviews.filter((review) => !review.deleted).map((review, index) => (
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: 'auto' }}
              exit={{ height: 0 }}
              className='review__wrapper' key={review.id}
            >
              <div className='review__item'>
                <Input
                  type='text'
                  floatingLabel
                  label={t('Title')}
                  value={review.title}
                  onChange={(value) => updateReview(review.id, 'title', value)}
                />
                <Textarea
                  floatingLabel
                  label={t('Review')}
                  value={review.review}
                  onChange={(value) => updateReview(review.id, 'review', value)}
                />
                <Input
                  type='text'
                  floatingLabel
                  label={t('Customer name')}
                  value={review.customer_name}
                  onChange={(value) => updateReview(review.id, 'customer_name', value)}
                />
                <Input
                  type='number'
                  floatingLabel
                  label={t('Rating')}
                  value={review.rating}
                  onChange={(value) => updateReview(review.id, 'rating', value)}
                  step='0.5'
                  min='0'
                  max='5'
                />
                <DatePicker
                  autoComplete='off'
                  placeholderText={t('-- Select a date --')}
                  selected={review.review_date ? new Date(review.review_date) : null}
                  onChange={(value) => updateReview(review.id, 'review_date', value ? format(value, 'yyyy-MM-dd') : null)}
                  locale={userCountry.code}
                  isClearable
                  dateFormat='PP'
                />
                <Input
                  type='text'
                  floatingLabel
                  label={t('Source ID')}
                  value={review.source_id}
                  onChange={(value) => updateReview(review.id, 'source_id', value)}
                />
                <div className='react-select__floating-label input'>
                  <ReactSelect
                    defaultValue={userLanguage ? { value: userLanguage, label: Language.getLanguageName(userLanguage) } : null}
                    value={review.language ? { value: Language.getIsoLocale(review.language), label: Language.getLanguageName(review.language) } : null}
                    options={Object.keys(Language.LANGUAGE_NAMES).map((key) => ({ value: key, label: Language.LANGUAGE_NAMES[key] }))}
                    className='lavendla-select'
                    classNamePrefix='lavendla-select'
                    onChange={value => updateReview(review.id, 'language', Language.getLanguageFromIsoLocale(value.value))}
                    placeholder={t('-- Select language --')}
                  />
                  <label htmlFor='language'>{t('Language')}</label>
                </div>
                <LoadingButton
                  className='review__remove'
                  text={t('Remove')}
                  onClick={() => removeRow(review.id)}
                />
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
      <LoadingButton
        onClick={addRow}
        disabled={!canAddMore()}
        text={t('Add new review')}
      />
    </>
  );
};

export default ReviewsTab;
