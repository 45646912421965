import React from 'react';
import useSingleOrder from './useSingleOrder';
import API from '../../stores/API';
import { showNotice, showWarningNotice } from 'Library/notifications';
import { Trans, useTranslation } from 'react-i18next';

function useOrderProducts () {
  const { order, orderActionCallback } = useSingleOrder();
  const { t } = useTranslation();

  async function attachProduct (productId, data = {}) {
    const response = await API.POST(`/order-products/${order.id}/${productId}/${order.country_id}?order_type=${order.class}`, data);

    return orderActionCallback(response);
  }

  async function attachWcProduct (wcProductId, data = {}) {
    const response = await API.POST(`/order-products/${order.id}/wc-product/${wcProductId}/${order.country_id}?order_type=${order.class}`, data);

    return orderActionCallback(response);
  }

  async function updateProduct (productResourceId, data = {}) {
    const response = await API.PUT(`/order-products/${order.id}/${productResourceId}?order_type=${order.class}`, data);

    return orderActionCallback(response);
  }

  async function detachProduct (productResourceId) {
    const response = await API.DELETE(`/order-products/${order.id}/${productResourceId}?order_type=${order.class}`);

    return orderActionCallback(response);
  }

  async function toggleCertified (productResourceId, invoiced) {
    const response = await API.POST(`/order-products/${order.id}/${productResourceId}/${order.country_id}/toggle-certified?order_type=${order.class}`, { invoiced });

    return orderActionCallback(response);
  }

  async function toggleExtendPaymentProduct (detach = true) {
    const response = await API.POST(`/order-products/${order.id}/toggle-extend-payment-product?order_type=${order.class}`, { detach });

    return toggleProductCallback(detach, t('Extended payment'), response);
  }

  async function togglePaperInvoiceProduct (detach = true) {
    const response = await API.POST(`/order-products/${order.id}/toggle-paper-invoice-product?order_type=${order.class}`, { detach });

    return toggleProductCallback(detach, t('Paper Invoice'), response);
  }

  async function toggleOrderViewing (detach = true) {
    const response = await API.POST(`/orders/${order.id}/toggle-viewing-product`, { detach });

    return toggleProductCallback(detach, 'Visning', response);
  }

  async function toggleCleanUpProduct (detach = true) {
    const response = await API.POST(`/orders/${order.id}/toggle-clean-up-product`, { detach });

    return toggleProductCallback(detach, 'Tömning och bortforsling', response);
  }

  async function toggleEstateAgentEvaluationProduct (detach = true) {
    const response = await API.POST(`/orders/${order.id}/toggle-estate-agent-evaluation-product`, { detach });

    return toggleProductCallback(detach, 'Värdering bostad', response);
  }

  async function toggleEstateAgentSaleProduct (detach = true) {
    const response = await API.POST(`/orders/${order.id}/toggle-estate-agent-sale-product`, { detach });

    return toggleProductCallback(detach, 'Försäljning bostad', response);
  }

  async function toggleCivilOfficial (officialId = null) {
    const civicOfficialNames = {
      se: 'Borgerlig officiant',
      dk: 'Ceremonileder',
      no: 'Seremonileder',
      fi: 'Siviilivirkailija'
    };

    const response = await API.POST(`/orders/${order.id}/update-civil-official`, { official_supplier_id: officialId });
    const productName = civicOfficialNames[response.data.country.code];
    return toggleProductCallback(!officialId, productName, response);
  }

  async function toggleAdvisorOsaProduct (detach = true) {
    const response = await API.POST(`/orders/${order.id}/toggle-advisor-handles-osa-product`, { detach });

    return toggleProductCallback(detach, 'Mottagning av anmälningar', response);
  }

  async function toggleCoffinBearersProduct (type) {
    const response = await API.POST(`/orders/${order.id}/update-entombment-type`, { type });

    return orderActionCallback(response);
  }

  function showProductAddedNotice (productName) {
    showNotice(
      <Trans productName={productName}>
        The product {{ productName }} has been added to the order
      </Trans>
    );
  }

  function showProductRemovedNotice (productName) {
    showWarningNotice(
      <Trans productName={productName}>
        The product {{ productName }} has been removed from the order
      </Trans>
    );
  }

  function toggleProductCallback (detach, productName, response) {
    if (detach) {
      showProductRemovedNotice(productName);
    } else {
      showProductAddedNotice(productName);
    }

    return orderActionCallback(response);
  }

  function getImgSrc (media, size = 'medium') {
    if (!media?.sizes || typeof media.sizes === 'undefined') {
      return '';
    }

    return (typeof media.sizes[size] !== 'undefined') ? media.sizes[size] : '';
  }

  return {
    showProductAddedNotice,
    showProductRemovedNotice,
    attachProduct,
    attachWcProduct,
    detachProduct,
    updateProduct,
    toggleCertified,
    toggleCleanUpProduct,
    toggleOrderViewing,
    toggleEstateAgentEvaluationProduct,
    toggleEstateAgentSaleProduct,
    toggleAdvisorOsaProduct,
    toggleCivilOfficial,
    toggleCoffinBearersProduct,
    getImgSrc,
    toggleExtendPaymentProduct,
    togglePaperInvoiceProduct
  };
}

export default useOrderProducts;
