import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatMinutes } from 'Library/functions';
import EditLoggedTime from './EditLoggedTime';
import UserImage from '../UserImage';
import { useTranslation } from 'react-i18next';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';

const LoggedTime = ({ loggedTime, persist, remove, updating = false }) => {
  const { loggedInUserCountry: country } = useLoggedInUser();
  const { t } = useTranslation();
  const [editing, updateEditing] = useState(false);

  return (
    <div className='logged-time'>
      {editing
        ? (
          <div className='logged-time__edit'>
            <h4 className='create-logged-time__header'>{t('Edit task')}</h4>
            <EditLoggedTime
              loggedTime={loggedTime}
              reset={() => updateEditing(false)}
              persist={(data, id) => {
                updateEditing(false);
                persist(data, id);
              }}
            />
          </div>
          )
        : (
          <>
            <div className='logged-time__meta'>
              <time dateTime={`${loggedTime.date} ${loggedTime.time}`} className='logged-time__meta__date'>{loggedTime.date} {loggedTime.time}</time>

              <div className='logged-time__meta__user-data'>
                <UserImage
                  user={loggedTime.user}
                  width={40}
                  height={40}
                />
                <div className='logged-time__meta__user-data__text'>
                  <span className='logged-time__meta__title'><strong>{loggedTime.user.name}</strong>: {loggedTime.title}</span>
                </div>
              </div>

              <span className='logged-time__meta__time'>{formatMinutes(loggedTime.time_spent, country.code)} {t('logged')}</span>
            </div>

            <div className='logged-time__actions meta'>
              <button
                disabled={updating}
                className='logged-time__actions__button remove bbl'
                onClick={e => {
                  e.preventDefault();

                  if (window.confirm(t('Are you sure?'))) {
                    remove(loggedTime.id);
                  }
                }}
              >{t('Remove')}
              </button>
              <button
                disabled={updating}
                className='logged-time__actions__button edit bbr'
                onClick={e => {
                  e.preventDefault();
                  updateEditing(true);
                }}
              >{t('Edit')}
              </button>
            </div>
          </>
          )}
    </div>
  );
};

LoggedTime.propTypes = {
  loggedTime: PropTypes.object.isRequired,
  persist: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  updating: PropTypes.bool
};

export default LoggedTime;
