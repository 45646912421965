import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LavendlaModal from './Modal';
import ChildCategories from './ChildCategories';
import Funnel from './Funnel';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from 'ReduxActions/products';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { useTranslation } from 'react-i18next';

const CategoryRow = ({
  tombstoneOrder,
  lawyerOrder,
  category,
  isDisabled = false
}) => {
  const { t } = useTranslation();
  const { order } = useSingleOrder();
  const { products } = useSelector(state => state.products);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isProduct, setIsProduct] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);

  const getProductsFromAPI = async () => {
    setLoadingProducts(true);
    await dispatch(getProducts(category.id));

    setOpen(true);
    setLoadingProducts(false);
  };

  const closeModal = () => {
    setOpen(false);
    setLoadingProducts(false);
  };

  const getParent = () => {
    return document.querySelector('.order-content');
  };

  const getImgSrc = (media, size = 'medium') => {
    if (!media?.sizes || typeof media.sizes === 'undefined') {
      return '';
    }

    return (typeof media.sizes[size] !== 'undefined') ? media.sizes[size] : '';
  };

  const thumbnail = (typeof category.media[0] !== 'undefined')
    ? getImgSrc(category.media[0])
    : '/images/default-thumbnail.jpg';

  const parentCategoryClass = classNames({ 'parent-category': category.children.length > 0 }, 'product-row-container');

  return (
    <div className={parentCategoryClass}>
      <div className='product-row'>
        <div className='col category-col'>
          {category.children.length > 0 || <div className='thumbnail-container'>
            <div className='thumbnail-container'>
              <img className='product-thumbnail' src={encodeURI(thumbnail)} alt='produkt-bild'/>
            </div>
          </div>}

          <div dangerouslySetInnerHTML={{ __html: category.name }}/>
        </div>

        {category.children.length > 0 || <div className='col action-col'>
          <button
            disabled={isDisabled}
            className={classNames('btn show-product-category', { loading: loadingProducts })}
            role='button'
            data-cy={`funnel-term_${category.id}-trigger`}
            onClick={e => {
              e.preventDefault();
              getProductsFromAPI();
            }}
          >
            <span className='btn-text'>{t('Show all')}</span>
            <span className='btn-loading'> </span>
          </button>
        </div>}
      </div>

      <LavendlaModal
        className='category-modal'
        close={closeModal}
        headerTitle={category.name}
        isOpen={open}
        isProduct={isProduct}
        parentSelector={getParent}
      >
        <Funnel
          orderInfo={{
            tombstoneOrder,
            lawyerOrder,
            order
          }}
          categoryName={category.name}
          products={products}
          setIsProduct={bool => setIsProduct(bool)}
        />
      </LavendlaModal>

      {category.children.length === 0 || <ChildCategories
        isDisabled={isDisabled}
        categories={category.children}
        tombstoneOrder={tombstoneOrder}
        lawyerOrder={lawyerOrder}
        order={order}
      />}
    </div>
  );
};

CategoryRow.propTypes = {
  tombstoneOrder: PropTypes.bool,
  lawyerOrder: PropTypes.bool,
  category: PropTypes.object,
  isDisabled: PropTypes.bool
};

export default CategoryRow;
