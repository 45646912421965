import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { formatUser } from 'Library/functions';
import { ROLES } from '../Redux/actions/users';
import useUsers from 'ReduxHooks/useUsers';
import { useTranslation } from 'react-i18next';

function getDefaultPlaceholder (lawyers) {
  const { t } = useTranslation();
  return (lawyers) ? ' -- ' + t('Choose a lawyer') + ' --' : ' -- ' + t('Choose an advisor') + ' --';
}

function UserDropdown ({
  user = null,
  onChange = null,
  all = false,
  lawyers = false,
  therapists = false,
  tombstoneUsers = false,
  officiantUsers = false,
  countryId = null,
  locationId = null,
  placeholder = getDefaultPlaceholder(lawyers),
  isClearable = true,
  withArchived = false,
  ...rest
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { dropdownUsers, loadDropdownUsers, clearDropdownUsers } = useUsers();

  useEffect(() => {
    if (user && user > 0) {
      fetchUsers();
    }
  }, [user]);

  useEffect(() => {
    clearDropdownUsers();
  }, [countryId, locationId]);

  async function fetchUsers () {
    if (isLoading) {
      return;
    }

    const filters = {
      role: getRole(),
      overrideCountryId: countryId,
      withArchived
    };

    if (locationId && !tombstoneUsers) {
      filters.locationId = locationId;
    }

    setIsLoading(true);
    await loadDropdownUsers(filters);
    setIsLoading(false);
  }

  function getRole () {
    if (all) {
      return '';
    }

    if (lawyers) {
      return ROLES.LEGAL;
    }

    if (therapists) {
      return ROLES.THERAPIST;
    }

    if (officiantUsers) {
      return ROLES.OFFICIANT;
    }

    return tombstoneUsers ? ROLES.TOMBSTONE : ROLES.FUNERAL;
  }

  function getDefaultUser () {
    const currentUser = dropdownUsers.find(({ id }) => id === parseInt(user));

    return currentUser ? formatUser(currentUser) : null;
  }

  return (
    <Select
      value={getDefaultUser()}
      isClearable={isClearable}
      className='lavendla-select'
      classNamePrefix='lavendla-select'
      onFocus={() => fetchUsers()}
      options={dropdownUsers.map(formatUser)}
      placeholder={isLoading ? t('Loading') + ' ...' : placeholder}
      onChange={value => onChange(value ? value.value : null)}
      {...rest}
    />
  );
}

UserDropdown.propTypes = {
  user: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func.isRequired,
  all: PropTypes.bool,
  lawyers: PropTypes.bool,
  tombstoneUsers: PropTypes.bool,
  therapists: PropTypes.bool,
  officiantUsers: PropTypes.bool,
  locationId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  countryId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  withArchived: PropTypes.bool
};

export default UserDropdown;
