import { library } from '@fortawesome/fontawesome-svg-core';

/*
 * How to add a new icon!
 * Go here and find an icon: https://fontawesome.com/icons?d=gallery&s=light  (We only use the light style)
 * Get the name of the icon, e.g kiwi-bird
 * Prefix the name with fa and convert it to camelCase, e.g faKiwiBird
 * Import that icon and add it to the library.
 *
 * When you want to reference the new icon in a component you use the name you first copied, e.g kiwi-bird.
 * Until Font Awesome fixes their shit we need to tell them that wer changed from the default icon theme to the light one EVERY TIME we use an Icon.
 * light = fal
 *
 * import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
 *
 * <FontAwesomeIcon icon={['fal', 'kiwi-bird']} />
*/

import {
  faTimes,
  faInfoCircle,
  faCheckCircle,
  faQuestionCircle,
  faExclamationTriangle,
  faChevronDown,
  faUser,
  faUserGraduate,
  faMapMarkerAlt,
  faTombstoneAlt,
  faFileInvoiceDollar,
  faCalendarAlt,
  faUsers,
  faMobileAndroidAlt,
  faBalanceScale,
  faChevronCircleDown,
  faIdCard,
  faPlus,
  faCheck,
  faBookReader,
  faGlobe,
  faTasks,
  faSlidersVSquare,
  faPen,
  faPhoneAlt,
  faComments,
  faGifts,
  faBadgeCheck,
  faLock,
  faLockOpen,
  faMicroscope,
  faStar,
  faTag,
  faFileContract
} from '@fortawesome/pro-light-svg-icons';

import {
  faGoogle
} from '@fortawesome/free-brands-svg-icons';
import {
  faStar as fasStar,
  faSpinner,
  faEarthAmericas
} from '@fortawesome/pro-solid-svg-icons';

const initializeIcons = () => {
  library.add(
    // times
    faTimes,
    // info-circle
    faInfoCircle,
    // check-circle
    faCheckCircle,
    // question-circle
    faQuestionCircle,
    // exclamation-triangle
    faExclamationTriangle,
    // chevron-down
    faChevronDown,
    // user
    faUser,
    // user-graduate
    faUserGraduate,
    // map-marker-alt
    faMapMarkerAlt,
    // tombstone-alt
    faTombstoneAlt,
    // file-invoice-dollar
    faFileInvoiceDollar,
    // calendar-alt
    faCalendarAlt,
    // users
    faUsers,
    // balance-scale
    faBalanceScale,
    // chevron-circle-down
    faChevronCircleDown,
    // id-card
    faIdCard,
    // plus
    faPlus,
    // check
    faCheck,
    // book (competenceAreas)
    faBookReader,
    // compass
    faGlobe,
    // tasks
    faTasks,
    // sliders-v-square
    faSlidersVSquare,
    // pen
    faPen,
    // phone-alt
    faPhoneAlt,
    // mobile-android-alt
    faMobileAndroidAlt,
    // comments
    faComments,
    // gifts
    faGifts,
    // badge-check
    faBadgeCheck,
    // lock
    faLock,
    // lock-open
    faLockOpen,
    // microscope
    faMicroscope,
    // star
    faStar,
    // solid star
    fasStar,
    // spinner
    faSpinner,
    // google
    faGoogle,
    // tag
    faTag,
    // file-contract
    faFileContract,
    // earth-americas
    faEarthAmericas
  );
};

export default initializeIcons;
