import React from 'react';
import { Redirect } from 'react-router';
import Route from './Route';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import PropTypes from 'prop-types';

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const {
    loggedInUser,
    isAdmin,
    isLegalAdvisor,
    isFuneralAdvisor,
    isTombstoneAdvisor,
    isTherapist,
    isOfficiant,
    isSupplier
  } = useLoggedInUser();

  if (!loggedInUser) {
    return null;
  }

  /**
   * Check whether or not the current user has access
   * to the current component.
   *
   * @return {boolean}
   */
  const userHasAccess = () => {
    if (isAdmin) {
      return true;
    }

    if (rest.admin) {
      return isAdmin;
    }

    if (rest.lawyer) {
      return isLegalAdvisor;
    }

    if (rest.supplier) {
      return isSupplier;
    }

    if (rest.funeral) {
      return isFuneralAdvisor;
    }

    if (rest.tombstone) {
      return isTombstoneAdvisor;
    }

    if (rest.therapist) {
      return isTherapist;
    }

    if (rest.officiant) {
      return isOfficiant;
    }

    if (rest.allExceptSuppliers) {
      return isAdmin || isTombstoneAdvisor || isFuneralAdvisor || isLegalAdvisor;
    }

    if (rest.adminOrAdvisor) {
      return isAdmin || isLegalAdvisor || isTherapist || isFuneralAdvisor || isTombstoneAdvisor || isOfficiant;
    }

    return true;
  };

  return (
    <Route
      {...rest}
      render={props => {
        props = {
          ...props,
          ...rest,
          ...{
            loggedInUser,
            isAdmin,
            isLegalAdvisor,
            isTombstoneAdvisor,
            isFuneralAdvisor,
            isSupplier
          }
        };

        if (rest.location.pathname === '/anvandare') {
          return <Redirect to='/anvandarregister' />;
        }

        if (userHasAccess()) {
          return <Component {...props} />;
        }

        if (rest.path === '/') {
          if (isLegalAdvisor) {
            return <Redirect to='/juridik-stats' />;
          }
          if (isTherapist) {
            return <Redirect to='/therapy-stats' />;
          }
          if (isTombstoneAdvisor) {
            return <Redirect to='/gravsten-stats' />;
          }
          if (isSupplier) {
            return <Redirect to='/kalender' />;
          }
        }

        return <Redirect to='/403' />;
      }}
    />
  );
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired
};

export default AuthenticatedRoute;
