import React from 'react';
import PropTypes from 'prop-types';
import MemorialPages from './Graphs/MemorialPages';
import MeetingTypes from './Graphs/MeetingTypes';
import ConversionRates from './Graphs/ConversionRates';
import SidebarMobileToggle from '../Sidebar/SidebarMobileToggle';
import AverageOrder from './Graphs/AverageOrder';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import UserScheduleInfo from './components/UserScheduleInfo';
import Trustpilot from './components/Trustpilot';
import OrderStatus from './Graphs/OrderStatus';
import ActiveOrdersForUsers from './Graphs/ActiveOrdersForUsers';
import { AdminOnly, If } from 'Conditionals';
import { useTranslation } from 'react-i18next';
import MemorialPagesConversion from './Graphs/MemorialPagesConversion';
import CalendarSyncAuth from 'Components/CalenderSyncAuth';
import WeeklyReport from 'Components/WeeklyReport';

function DashboardContent ({ orderType = 'App\\Order' }) {
  const { t } = useTranslation();
  const { isAdmin, loggedInUser, loggedInUserCountry } = useLoggedInUser();

  return (
    <div className='dashboard-content__container'>
      <div className='dashboard-graphs'>
        <div className='row row-graph'>
          <div className='col-12'>
            {isAdmin && loggedInUserCountry.name
              ? (
                <h1>
                  {t('Overview')} {loggedInUserCountry.name}
                </h1>
                )
              : (
                <h1>{t('Overview')}</h1>
                )}

            <UserScheduleInfo />

            <CalendarSyncAuth user={loggedInUser} />

            <WeeklyReport user={loggedInUser} orderType={orderType} />

            <Trustpilot user={loggedInUser} />

            <OrderStatus orderType={orderType} />

            <AdminOnly>
              <If condition={orderType === 'App\\Order'}>
                <MemorialPages orderType={orderType} />
              </If>
            </AdminOnly>

            {orderType === 'App\\Order'
              ? (
                <ConversionRates orderType={orderType} />
                )
              : null}
            {orderType === 'App\\Order'
              ? (
                <MemorialPagesConversion orderType={orderType} />
                )
              : null}

            <AdminOnly>
              <If
                condition={
                  orderType === 'App\\Order' ||
                  orderType === 'App\\LawyerOrder' ||
                  orderType === 'App\\TherapyOrder'
                }
              >
                <MeetingTypes orderType={orderType} />
              </If>
            </AdminOnly>
          </div>
        </div>
        <AdminOnly>
          <div className='row row-graph'>
            <div className='col-12'>
              <AverageOrder orderType={orderType} />
            </div>
          </div>
        </AdminOnly>
        <If
          condition={isAdmin}
        >
          <div className='row row-graph'>
            <div className='col-12'>
              <ActiveOrdersForUsers orderType={orderType} />
            </div>
          </div>
        </If>
      </div>
      <div className='mobile-toggle-sidebar__container'>
        <SidebarMobileToggle>
          <span className='mobile-toggle-sidebar__wrapper'>
            <span className='mobile-toggle-sidebar__open mobile-toggle-sidebar__text'>
              {t('Show calendar')}
            </span>
            <span className='mobile-toggle-sidebar__close mobile-toggle-sidebar__text'>
              {t('Hide calendar')}
            </span>
          </span>
        </SidebarMobileToggle>
      </div>
    </div>
  );
}

DashboardContent.propTypes = {
  orderType: PropTypes.string
};

export default DashboardContent;
