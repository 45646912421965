import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { formatLocation, getFormattedLocationById } from '../formatters';
import useLocations from 'ReduxHooks/useLocations';
import { generateId } from 'Library/functions';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const LocationDropdown = ({
  value,
  onChange,
  filters = {},
  id = generateId(),
  isMulti = false,
  disabled = false,
  showParents = false,
  placeholder = null,
  countryId = null
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { getLocations } = useLocations();
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    async function fetchData () {
      setIsLoading(true);
      await getLocations({ ...filters, ...{ countryId } }).then(setLocations);
      setIsLoading(false);

      getOptions();
      getValue();
    }

    fetchData();
  }, [countryId]);

  /**
   * Only show options that are children (the parents are
   * 'Skåne län', 'Stockholms län' etc.
   *
   * There is even the noteable exeption of locations
   * 'Okänd', 'Unknown', 'Ukendt' which should be shown
   *
   * @return {Array}
   */
  function getOptions () {
    if (!locations?.length) {
      return [];
    }

    let options = [...locations];

    if (!showParents) {
      options = options.filter(({ parent_id: parentId, name }) => {
        if (parentId > 0) {
          return true;
        }

        return ['Okänd', 'Unknown', 'Ukendt'].includes(name);
      });
    }

    return options.map(formatLocation);
  }

  const mapValue = value => {
    if (typeof value === 'object') {
      return value;
    }

    return getFormattedLocationById(value, locations);
  };

  const isDisabled = () => {
    return !countryId || isLoading || disabled;
  };

  const placeholderText = () => {
    if (!countryId) {
      return t('Select a country first');
    }

    return isLoading ? t('Loading places...') : placeholder || t('-- Select a location --');
  };

  function getValue () {
    if (!locations?.length) {
      return null;
    }

    if (Array.isArray(value)) {
      return value.map(mapValue);
    }

    return mapValue(value);
  }

  return (
    <Select
      id={id}
      isMulti={isMulti}
      value={getValue()}
      isDisabled={isDisabled()}
      options={getOptions()}
      className='lavendla-select location-dropdown'
      classNamePrefix='lavendla-select'
      onChange={newLocation => onChange(newLocation)}
      placeholder={placeholderText()}
    />
  );
};

LocationDropdown.propTypes = {
  id: PropTypes.string,
  value: PropTypes.any,
  filters: PropTypes.object,
  isMulti: PropTypes.bool,
  disabled: PropTypes.bool,
  showParents: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  countryId: PropTypes.number
};

export default LocationDropdown;
