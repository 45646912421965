import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import useSuppliers from 'Hooks/useSuppliers';
import API from '../../stores/API';
import { showNotice, showWarningNotice } from 'Library/notifications';
import { useTranslation } from 'react-i18next';

const UploadSupplierExpectedCostFile = ({ supplier }) => {
  const { t } = useTranslation();
  const file = useRef([]);
  const { loadExpectedCostFile, uploadExpectedCostFile } = useSuppliers(supplier.country);
  const [isUploadBtnDisabled, setIsUploadBtnDisabled] = useState(true);
  const [expectedCostFile, setExpectedCostFile] = useState(null);

  useEffect(() => {
    if (supplier && ['transportoer'].includes(supplier.type)) {
      fetchFile();
    }
  }, [supplier]);

  async function fetchFile () {
    const { status, data } = await loadExpectedCostFile(supplier.id);

    if (status === 'ok') {
      setExpectedCostFile(data);
    } else {
      showWarningNotice(t('An error occurred when loading the file'));
    }
  }

  const onFileChange = e => {
    setIsUploadBtnDisabled(false);
  };

  const onUploadBtnClick = async () => {
    if (file.current.files.length < 1) {
      return null;
    }

    const formData = new FormData();
    formData.append(
      'expected_cost_csv',
      file.current.files[0],
      file.current.files[0].name
    );

    const { status, data, errors } = await uploadExpectedCostFile(supplier.id, formData);
    if (status !== 'ok') {
      showWarningNotice(`${t('An error occurred.')} <strong>${errors.error_message}</strong>`);
      return;
    }

    setExpectedCostFile(data);
    showNotice(`🎉 <strong>${file.current.files[0].name}</strong> ${t('has been uploaded')}`);
    file.current.value = null;
    setIsUploadBtnDisabled(true);
  };

  const onDeleteFileClick = async (e) => {
    const { status, errors } = await API.DELETE(`/files/delete/${expectedCostFile.id}`);
    if (status === 'ok') {
      setExpectedCostFile(null);
      return;
    }

    showWarningNotice(`${t('An error occurred.')} <strong>${errors.error_message}</strong>`);
  };

  if (!['transportoer'].includes(supplier.type)) {
    return null;
  }

  return (
    <div className='supplier-expected-cost-file'>
      <h3>{t('Estimated transport cost:')}</h3>

      {expectedCostFile && (
        <div className='expected-cost-file'>
          <a href={expectedCostFile.link} target='_blank' rel='noopener noreferrer' alt={expectedCostFile.filename}>{t('uploaded at')} {expectedCostFile.created_at}</a>
          <button className='btn delete' onClick={onDeleteFileClick}>{t('Delete')}</button>
        </div>
      )}

      <input
        type='file'
        ref={file}
        onChange={onFileChange}
      />

      <button
        className='btn'
        role='button'
        onClick={onUploadBtnClick}
        disabled={isUploadBtnDisabled}
      >{t('Upload')}
      </button>
    </div>
  );
};

UploadSupplierExpectedCostFile.propTypes = {
  supplier: PropTypes.object.isRequired
};

export default UploadSupplierExpectedCostFile;
