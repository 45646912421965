import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useOrderShortcodes from 'Hooks/useOrderShortcodes';
import useTask from 'ReduxHooks/useTask';
import Collapsible from 'react-collapsible';

function ListShortcodes ({ type = 'all' }) {
  const { t } = useTranslation();
  const { getShortcodes } = useOrderShortcodes({ type });
  const { taskCountry } = useTask();
  const [shortcodes, setShortcodes] = useState(getShortcodes());

  useEffect(() => {
    setShortcodes(getShortcodes());
  }, [taskCountry]);

  function getHeader (key) {
    const dict = {
      order: t('Order'),
      invoice_options: t('Faktura'),
      customer: t('Customer'),
      deceased: t('Deceased'),
      entombment: t('Entombment'),
      funeral: t('Ceremony'),
      transport: t('Transport'),
      memorial: t('Memorial'),
      memorial_page: t('Memorial page'),
      products: t('Products'),
      suppliers: t('Suppliers'),
      appointments: t('Appointments'),
      offer: t('Offer')
    };

    return dict[key] || '';
  }

  return (
    <div className='list-shortcodes'>
      {Object.keys(shortcodes).map((key, i) => (
        <Collapsible
          transitionTime={150}
          trigger={getHeader(key)} key={i}
        >
          <ul>
            {Object.keys(shortcodes[key]).map((keyTwo, j) => (
              <li key={j}>
                <span>{shortcodes[key][keyTwo]}</span>
                <span>[{key}.{keyTwo}]</span>
              </li>
            ))}
          </ul>
        </Collapsible>
      ))}
    </div>
  );
}

ListShortcodes.propTypes = {
  type: PropTypes.string
};

export default ListShortcodes;
