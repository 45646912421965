import i18next from 'i18next';
// eslint-disable-next-line camelcase
import { number_format } from 'locutus/php/strings';
import humanizeDuration from 'humanize-duration';
import moment from 'moment';
import { parse, isValid, format } from 'date-fns';
import { sv, nb, enGB } from 'date-fns/locale';
import { getResourceUrl } from 'Library/Orders';

export function formatAsOption ({ name, id }) {
  return {
    label: name,
    value: id
  };
}

export function formatUser ({ name, id, archived_at: archivedAt = null }) {
  let newName = name;
  if (archivedAt) {
    newName += i18next.t(' (Inactive)');
  }

  return formatAsOption({ name: newName, id });
}

export function filterNonLocalUsers ({ locations }, locationId) {
  if (!locationId || !locations) {
    return true;
  }

  return !locations.find(location => parseInt(location) === parseInt(locationId));
}

export function filterLocalSuppliers ({ locations }, locationId) {
  if (!locationId || !locations) {
    return true;
  }

  return !!locations.find(({ id }) => id === locationId);
}

export function filterNonLocalSuppliers (supplier, locationId) {
  return filterNonLocalUsers(supplier, locationId);
}

export function generateId (id = null) {
  if (id) {
    return id;
  }

  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

export function formatCurrency (number, decimals = null, countryCode = '', currency = null) {
  let symbol; let thousandsSep; let decPoint; let symbolPlacement = 'after';
  if (currency) {
    symbol = currency.symbol;
    thousandsSep = currency.thousand_separator || ' ';
    decPoint = currency.decimal_separator;
    symbolPlacement = currency.symbol_placement;
  } else {
    symbol = getCurrencySymbolFromCountryCode(countryCode);
    thousandsSep = getThousandsSepCountryCode(countryCode);
    decPoint = getDecPointCountryCode(countryCode);
    symbolPlacement = getCurrencyPosition(countryCode);
  }

  if (!decimals) {
    decimals = 0;
  }

  if (symbolPlacement === 'before') {
    return number < 0 ? `-${symbol}${number_format(Math.abs(number), decimals, decPoint, thousandsSep)}` : `${symbol}${number_format(Math.abs(number), decimals, decPoint, thousandsSep)}`;
  }

  return `${number_format(number, decimals, decPoint, thousandsSep)} ${symbol}`;
}

export function objectHasEmptyValues (obj = {}) {
  return Object.entries(obj)
    .some(([, value]) => !value);
}

export function formatMinutes (minutes, countryCode) {
  minutes = (minutes === 0) ? 0 : minutes * 60000;

  const args = {
    language: getLanguageFromCountryCode(countryCode)
  };

  return humanizeDuration(minutes, args);
}

export function getDisabledMinutesFortTimePicker () {
  const skipmins = [];

  for (let i = 1; i < 60; i++) {
    if (i % 5 !== 0) {
      skipmins.push(i);
    }
  }

  return skipmins;
}

export function formatDate (date = null, countryCode = 'se', dateFormat = 'Pp') {
  if (!date) {
    return '';
  }

  const locale = {
    se: sv,
    dk: sv, // Fix for DK as date-fns does not format danish dates correctly
    no: nb,
    gb: enGB
  };

  const validDate = getValidatedDate(date);
  return validDate ? format(validDate, dateFormat, { locale: locale[countryCode] }) : '';
}

export const getValidatedDate = (date) => {
  let validDate = isValid(parse(date, 'yyyy-MM-dd HH:mm:ss', new Date())) ? parse(date, 'yyyy-MM-dd HH:mm:ss', new Date()) : null;
  validDate = validDate || (isValid(parse(date, 'yyyy-MM-dd HH:mm', new Date())) ? parse(date, 'yyyy-MM-dd HH:mm', new Date()) : null);
  return validDate;
};

export const getCurrencySymbolFromCountryCode = countryCode => {
  switch (countryCode.toLowerCase()) {
    case 'se':
      return 'kr';
    case 'dk':
      return 'kr';
    case 'de':
      return '€';
    case 'global':
      return '£';
    default:
      return 'kr';
  }
};

export const getCurrencyPosition = countryCode => {
  switch (countryCode.toLowerCase()) {
    case 'de':
    case 'global':
      return 'before';
    default:
      return 'after';
  }
};

export const getThousandsSepCountryCode = countryCode => {
  switch (countryCode.toLowerCase()) {
    case 'global':
      return ',';
    default:
      return ' ';
  }
};

export const getDecPointCountryCode = countryCode => {
  switch (countryCode.toLowerCase()) {
    case 'global':
      return '.';
    default:
      return ',';
  }
};

export const getDefaultDecimals = countryCode => {
  switch (countryCode.toLowerCase()) {
    case 'de':
    case 'global':
      return 2;
    default:
      return 0;
  }
};

export function formatProductPrice ({ price, pivot_price: pivotPrice, quantity, separate_price: separatePrice = null }, countryCode = '', currency = null) {
  if (pivotPrice) {
    return formatCurrency(pivotPrice * quantity, null, countryCode, currency);
  }

  if (separatePrice) {
    return i18next.t('Separate price');
  }

  return formatCurrency(price * quantity, null, countryCode, currency);
}

export function readableDiffSince (timestamp, countryCode) {
  const instance = moment(timestamp);
  const since = instance.diff(moment());

  if (instance.diff(moment(), 'hours') === 0) {
    return i18next.t('Less than one hour');
  }

  const args = {
    language: getLanguageFromCountryCode(countryCode),
    units: ['d', 'h'],
    round: true
  };

  return humanizeDuration(since, args);
}

export const getLanguageFromCountryCode = countryCode => {
  switch (countryCode.toLowerCase()) {
    case 'se':
      return 'sv';
    case 'dk':
      return 'da';
    case 'de':
      return 'de';
    case 'global':
      return 'en-GB';
    default:
      return 'sv';
  }
};

export function parseQueryString (queryString) {
  const params = new URLSearchParams(queryString);
  const result = {};
  for (const [key, value] of params.entries()) {
    result[key] = value;
  }
  return result;
}

export function getQueryParams (params = {}) {
  let queryParams = '';

  for (const key of Object.keys(params)) {
    if (params[key]) {
      queryParams += '&' + key + '=' + params[key];
    }
  }

  return encodeURI(queryParams);
}

export function getAssignedUserForOrder (order) {
  if (!order) {
    return null;
  }

  // eslint-disable-next-line no-prototype-builtins
  if (order.hasOwnProperty('assigned_to')) {
    return (order.assigned_to) ? order.user : null;
  }

  return (order.user_id) ? order.user : null;
}

export function getFuneralPlace ({ funeral = null }) {
  if (!funeral) {
    return '';
  }

  const { suppliers = [], place } = funeral;
  const supplier = suppliers.find(supplier => ['Kyrkor', 'Kapell'].includes(supplier.type));

  return (supplier) ? supplier.name : place;
}

export function getDefautInvoiceOptions (order) {
  const defaultOptions = {
    is_extend_payment: 0,
    is_paper: 0,
    email: '',
    is_installment: 0
  };

  return order.invoice_options ? order.invoice_options : defaultOptions;
}

export function getOrderLink (order) {
  return getResourceUrl(order);
}

export function getOrderPersonOfReference ({ customer, deceased, case_type: caseType, class: orderClass }) {
  switch (true) {
    case orderClass === 'App\\LawyerOrder' && !['Bouppteckning', 'Arvskifte', 'Dödsboförvaltning'].includes(caseType):
    case orderClass === 'App\\TherapyOrder':
    case orderClass === 'App\\CeremonyOrder':
      return (customer && customer.name !== ' ' && customer.name !== '') ? customer.name : i18next.t('Missing name');
    default:
      return (deceased && deceased.name !== ' ' && deceased.name !== '') ? deceased.name : i18next.t('Deceased name');
  }
}

export function getFuneralType (type) {
  const dict = {
    direktkremation: i18next.t('Direct Cremation'),
    akt_med_urna: i18next.t('Funeral with urn'),
    kremation: i18next.t('Cremation after ceremony'),
    jordbegravning: i18next.t('Burial')
  };

  return dict[type] ? dict[type] : i18next.t('Not selected');
}

export function getFuneralDivineEconomy (divineEconomy) {
  const dict = {
    christian: i18next.t('Christian'),
    svenska_kyrkan: i18next.t('Swedish church'),
    borgerlig: i18next.t('Civil'),
    katolsk: i18next.t('Catholic'),
    muslimsk: i18next.t('Muslim'),
    annan: i18next.t('Other')
  };

  return dict[divineEconomy] ? dict[divineEconomy] : i18next.t('Not selected');
}

export function getSupplierTypes () {
  return [
    {
      value: 'krematorium',
      label: i18next.t('Crematorium')
    },
    {
      value: 'barhus',
      label: i18next.t('Morgue')
    },
    {
      value: 'transportoer',
      label: i18next.t('Transportation provider')
    },
    {
      value: 'representant',
      label: i18next.t('Representative')
    },
    {
      value: 'kyrkogardsfoervaltning',
      label: i18next.t('Cemetery management', {
        description: 'Kyrkogårdsförvaltning'
      })
    },
    {
      value: 'foersamling',
      label: i18next.t('Parish')
    },
    {
      value: 'kistleverantoer',
      label: i18next.t('Coffin supplier')
    },
    {
      value: 'urnleverantoer',
      label: i18next.t('Urn supplier')
    },
    {
      value: 'cateringleverantoer',
      label: i18next.t('Catering supplier')
    },
    {
      value: 'blomleverantoer',
      label: i18next.t('Florist')
    },
    {
      value: 'jurist',
      label: i18next.t('Lawyer')
    },
    {
      value: 'tryckeri',
      label: i18next.t('Printer')
    },
    {
      value: 'solist',
      label: i18next.t('Singer')
    },
    {
      value: 'officiant',
      label: i18next.t('Official')
    },
    {
      value: 'gravstensleverantoer',
      label: i18next.t('Gravestone supplier')
    },
    {
      value: 'flytt-och-staedleverantoer',
      label: i18next.t('Removals/cleaners')
    },
    {
      value: 'moteslokal',
      label: i18next.t('Meeting venue')
    },
    {
      value: 'fastighetsmaklare',
      label: i18next.t('Estate agent')
    },
    {
      value: 'tomning_dodsbo',
      label: i18next.t('Estate inventory')
    },
    {
      value: 'minnesfond',
      label: i18next.t('Memorial fund')
    },
    {
      value: 'Begravningsplatser',
      label: i18next.t('Cemetery/Burial Ground')
    },
    {
      value: 'Församlingshem',
      label: i18next.t('Parish home')
    },
    {
      value: 'Kapell',
      label: i18next.t('Chapel')
    },
    {
      value: 'Kyrkor',
      label: i18next.t('Church')
    },
    {
      value: 'fotograf_filmare',
      label: i18next.t('Photographer')
    },
    {
      value: 'digitala_avtals_partner',
      label: i18next.t('Partner/Organization for Digital Contracts')
    }
  ];
}

export function getTranslatedStripeStatus (status) {
  switch (status) {
    case 'paid':
      return i18next.t('Paid');
    case 'pending':
      return i18next.t('Pending');
    case 'refunded':
      return i18next.t('Refunded');
    case 'missed':
      return i18next.t('Missed');
    default:
      return status;
  }
}
