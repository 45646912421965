import { useDispatch, useSelector } from 'react-redux';
import { showNotice } from 'Library/notifications';
import {
  getReviews,
  persistReply,
  persistRequiresModeration,
  updateReview,
  setIsLoading
} from '../actions/reviews';
import { useTranslation } from 'react-i18next';

function useReviews () {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { reviews, review, pagination, isLoading } = useSelector((state) => state.reviews);

  async function fetchReviews (filters) {
    await dispatch(setIsLoading(true));
    await dispatch(getReviews(filters));
    await dispatch(setIsLoading(false));
  }

  async function persistReviewForReply () {
    dispatch(setIsLoading(true));

    const response = await dispatch(persistReply());

    showNotice(t('The review has been updated'));

    dispatch(setIsLoading(false));

    return response;
  }

  async function persistReviewForRequiresModeration () {
    dispatch(setIsLoading(true));

    const response = await dispatch(persistRequiresModeration());

    showNotice(t('The review has been updated'));

    dispatch(setIsLoading(false));

    return response;
  }

  return {
    fetchReviews,
    persistReviewForReply,
    persistReviewForRequiresModeration,
    setReview: (data) => dispatch(updateReview(data)),
    isLoading,
    reviews,
    review,
    pagination
  };
}

export default useReviews;
