import { useDispatch, useSelector } from 'react-redux';
import { getLocations, resetLocations } from '../actions/locations';

function useLocations () {
  const { locations } = useSelector(state => state.locations);
  const dispatch = useDispatch();

  return {
    locations,
    resetLocations: () => dispatch(resetLocations()),
    getLocations: (filters = {}) => dispatch(getLocations(filters))
  };
}

export default useLocations;
