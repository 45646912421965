import React, { useState } from 'react';
import UserForm from './UserForm';
import UserProfileImage from './UserProfileImage';
import UserMeetingTimes from '../UserMeetingTimes/UserMeetingTimes';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import UserProfileMarkets from './UserProfileMarkets';
import UserEconomyTab from './UserEconomyTab';
import ReviewsTab from './ReviewsTab';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import UserNotificationSettingsTab from './UserNotificationSettingsTab';
import { AdminOnly, If } from 'Conditionals';
import { ROLES } from 'ReduxActions/users';
import AdminForm from './AdminForm';
import { LoadingButton } from 'FormElements';
import classNames from 'classnames';
import useUser from 'ReduxHooks/useUser';
import Spinner from '../Spinner';
import { useTranslation } from 'react-i18next';

const Profile = () => {
  const { t } = useTranslation();
  const { user, persist } = useUser();
  const { loggedInUser, isAdmin } = useLoggedInUser();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const isLoggedInUser = user.id === loggedInUser.id;
  const showNoticesTab = user.roles.includes(ROLES.FUNERAL) || isAdmin;

  if (!user) {
    return (
      <Spinner />
    );
  }

  const isPaused = !!user.paused_at;

  async function persistUser () {
    setIsLoading(true);
    await persist();
    setIsLoading(false);
  }

  return (
    <div className='row order-tab__form'>
      <form className='col-12 user-profile'>
        <h2 className='page-title'>
          {isLoggedInUser ? t('My profile') : t('Edit user')}
          <If condition={!!user.archived_at}>
            <em>&nbsp;({t('Inactive')})</em>
          </If>
          <If condition={!isLoggedInUser && isPaused}>
            <em>&nbsp;({t('This user is paused')})</em>
          </If>
        </h2>
        <Tabs onSelect={(index) => setActiveTab(index)}>
          <div className='row'>
            <div className='col-12'>
              <TabList
                className='react-tabs__tab-list react-tabs__has-sub-tabs'
              >
                <Tab className='react-tabs__tab'>{isLoggedInUser ? t('My profile') : t('User')}</Tab>
                <Tab className={classNames('react-tabs__tab', { hidden: !isAdmin })}>{t('Admin')}</Tab>
                <Tab className='react-tabs__tab'>{t('Schedule & meeting times')}</Tab>
                {user.countries.length ? (
                  <Tab className='react-tabs__tab'>{t('Profile')}</Tab>
                ) : null}
                {user.self_invoice || user.has_stripe_connect ? (
                  <Tab className='react-tabs__tab'>{t('Economy')}</Tab>
                ) : null}
                {showNoticesTab ? (
                  <Tab className='react-tabs__tab'>{t('Notifications')}</Tab>
                ) : null}
                <Tab className='react-tabs__tab'>{t('Reviews')}</Tab>
              </TabList>
            </div>
          </div>
          <TabPanel>
            <div className='row new-form'>
              <div className='col-8'>
                <UserForm />
              </div>
              <div className='col-4'>
                <UserProfileImage />
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className='row new-form'>
              <div className='col-8'>
                <AdminOnly>
                  <AdminForm />
                </AdminOnly>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <UserMeetingTimes />
          </TabPanel>
          {user.countries.length ? (
            <TabPanel>
              <UserProfileMarkets />
            </TabPanel>
          ) : null}
          {user.self_invoice || user.has_stripe_connect ? (
            <TabPanel>
              <div className='row new-form'>
                <div className='col-8'>
                  <UserEconomyTab />
                </div>
              </div>
            </TabPanel>
          ) : null}
          {showNoticesTab ? (
            <TabPanel>
              <UserNotificationSettingsTab />
            </TabPanel>
          ) : null}
          <TabPanel>
            <ReviewsTab />
          </TabPanel>
        </Tabs>

        <div className='btn-group'>
          {activeTab === 3 && user?.user_profiles.length === 0 ? null : (
            <LoadingButton
              onClick={() => persistUser()}
              text={t('Save')}
              data-cy='persist-user'
              isLoading={isLoading}
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default Profile;
