import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import useTags from 'ReduxHooks/useTags';
import { generateId } from 'Library/functions';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const TagsDropdown = ({
  value,
  onChange,
  countryId,
  id = generateId(),
  isMulti = false,
  disabled = false,
  placeholder = null,
  isLawyerOrder = false,
  isTombstoneOrder = false,
  isTherapyOrder = false,
  isCeremonyOrder = false
}) => {
  const { t } = useTranslation();
  const { tags, getTags, resetTags } = useTags();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (countryId) {
      fetchTags();
    } else {
      resetTags();
    }
  }, [countryId]);

  async function fetchTags () {
    setIsLoading(true);
    await getTags(countryId, getTagType());
    setIsLoading(false);
  }

  const getTagType = () => {
    if (isLawyerOrder) {
      return 'lawyer';
    }
    if (isTombstoneOrder) {
      return 'tombstone';
    }
    if (isTherapyOrder) {
      return 'therapy';
    }
    if (isCeremonyOrder) {
      return 'ceremony';
    }
    return 'funeral';
  };

  const getOptions = () => {
    if (!tags || !tags.length) {
      return [];
    }

    return tags.map(tag => ({ value: tag.id, label: tag.name }));
  };

  const mapValue = val => {
    const tag = tags.find(tag => tag.id === parseInt(val));
    return { value: tag.id, label: tag.name };
  };

  const getValue = () => {
    if (!tags || !tags.length) {
      return null;
    }

    if (Array.isArray(value)) {
      return value.map(mapValue);
    }

    return mapValue(value);
  };

  const isDisabled = () => {
    return !countryId || isLoading || disabled;
  };

  const placeholderText = () => {
    if (!countryId) {
      return t('Select a country first');
    }

    return isLoading ? t('Loading tags...') : placeholder || (isMulti ? t('-- Select tags --') : t('-- Select tag --'));
  };

  return (
    <Select
      id={id}
      isMulti={isMulti}
      value={getValue()}
      isDisabled={isDisabled()}
      options={getOptions()}
      className='lavendla-select'
      classNamePrefix='lavendla-select'
      onChange={newTags => onChange(newTags)}
      placeholder={placeholderText()}
      noOptionsMessage={() => isLoading ? t('Loading') + ' ...' : t('No tags found')}
    />
  );
};

TagsDropdown.propTypes = {
  id: PropTypes.string,
  value: PropTypes.any,
  isMulti: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  isLawyerOrder: PropTypes.bool,
  isTombstoneOrder: PropTypes.bool,
  isTherapyOrder: PropTypes.bool,
  isCeremonyOrder: PropTypes.bool
};

export default TagsDropdown;
