import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'Library/functions';
import { If } from 'Conditionals';
import { useTranslation } from 'react-i18next';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import usePaymentActions from 'Hooks/usePaymentActions';
import LavendlaModal from 'Components/Modal';
import Select from 'react-select';
import { LoadingButton } from 'FormElements';
import Notice from 'Layout/Notice';

function PaymentStatus ({ stripePayment }) {
  const { t } = useTranslation();
  const { loggedInUserCountry, isAdmin } = useLoggedInUser();
  const [showDeadlineModal, setShowDeadlineModal] = useState(false);
  const [newDeadline, setNewDeadline] = useState('48');
  const [deadlineDate, setDeadlineDate] = useState(stripePayment.payment_deadline);
  const [missedDeadlineDate, setMissedDeadlineDate] = useState(stripePayment.has_missed_payment_at);
  const [isUpdatingDeadline, setIsUpdatingDeadline] = useState(false);
  const { refundPayment, generateReceipt, updateDeadline } = usePaymentActions(stripePayment);

  const getReceipt = async () => {
    await generateReceipt();
  };

  const doRefund = async (stripePayment) => {
    if (!isAdmin && stripePayment.bonus_type === 'fixed' && stripePayment.bonus_amount !== 0) {
      alert(t('This payment includes Lavendla’s fee. Please contact customer service with a justification for why a refund needs to be made.'));
      return;
    }

    if (stripePayment.stripe_id && stripePayment.stripe_id.includes('pi_')) {
      alert(t('You need to refund this payment directly in Stripe Admin. If you need any help contact Support.'));
      return;
    }

    if (!window.confirm(t('Are you sure you want to refund this payment?'))) {
      return;
    }

    await refundPayment();
  };

  const openDeadlineMOdal = () => {
    setShowDeadlineModal(true);
  };

  const deadlines = [
    { value: '1', label: t('1 hour') },
    { value: '3', label: t('3 hours') },
    { value: '5', label: t('5 hours') },
    { value: '10', label: t('10 hours') },
    { value: '24', label: t('1 day') },
    { value: '48', label: t('2 days') },
    { value: '72', label: t('3 days') },
    { value: '96', label: t('4 days') }
  ];

  const getDeadlineSelectValue = (value) => {
    return deadlines.find(deadline => deadline.value === value);
  };

  const changeDeadline = async () => {
    setIsUpdatingDeadline(true);
    const { success, newDate } = await updateDeadline(newDeadline ? newDeadline.value : 48);
    setIsUpdatingDeadline(false);

    if (success) {
      setDeadlineDate(newDate);
      setMissedDeadlineDate(null);
      setShowDeadlineModal(false);
    }
  };

  if (!stripePayment.offer) {
    return null;
  }

  return (
    <div
      className='invoice-status'
      id='deadline-modal-container'
    >
      <span className='task-sent-date invoice-status__field created'>
        <strong>{t('Payment')} #{stripePayment.offer_id}</strong>
        &nbsp;-&nbsp;
        <a href={`${stripePayment.offer.link}/no-track`} target='_blank' rel='noopener noreferrer'>{t('Show payment')}</a>
        <br />
        <strong>{t('Created')}</strong> {formatDate(stripePayment.created_at, loggedInUserCountry.code)}
        <If condition={stripePayment.offer.viewed_at}>
          <br />
          <strong>{t('Viewed')}</strong> {formatDate(stripePayment.offer.viewed_at, loggedInUserCountry.code)}
        </If>
        <If condition={deadlineDate}>
          <br />
          <strong>{t('Payment deadline')}</strong> {formatDate(deadlineDate, loggedInUserCountry.code)}
          &nbsp;-&nbsp;
          <a onClick={() => openDeadlineMOdal()}>{t('Change')}</a>
          <If condition={missedDeadlineDate}>
            <If condition={missedDeadlineDate}>
              <br />
              <strong>{t('Missed deadline')}</strong> {formatDate(missedDeadlineDate, loggedInUserCountry.code)}
            </If>
          </If>
        </If>
        <If condition={stripePayment.paid_at}>
          <br />
          <strong>{t('Paid')}</strong> {formatDate(stripePayment.paid_at, loggedInUserCountry.code)}
            &nbsp;-&nbsp;
          <a onClick={() => getReceipt()} className='receipt'>{t('Receipt')}</a>
          <If condition={(stripePayment.status === 'paid' || stripePayment.status === 'refunded')}>
            &nbsp;
            <a onClick={() => doRefund(stripePayment)} className='refund-payment'>{t('Refund payment')}</a>
          </If>
        </If>
        <If condition={stripePayment.offer.verified}>
          <br />
          <strong>{t('Customer verified')}</strong> {formatDate(stripePayment.offer.verified, loggedInUserCountry.code)}
        </If>
        <If condition={stripePayment.refunded_at}>
          <br />
          <strong>{t('Payment refunded')}</strong> {formatDate(stripePayment.refunded_at, loggedInUserCountry.code)}
        </If>
      </span>
      <LavendlaModal
        className='category-modal'
        close={() => setShowDeadlineModal(false)}
        headerTitle={t('Change deadline')}
        isOpen={showDeadlineModal}
        parentSelector={() => document.querySelector('#deadline-modal-container')}
      >
        <div className='form-group'>
          <label htmlFor='payment_deadline'>{t('Payment deadline')}</label>
          <Select
            value={getDeadlineSelectValue(newDeadline)}
            onChange={paymentDeadline => setNewDeadline(paymentDeadline)}
            className='select'
            options={deadlines}
            placeholder={t('Select offer deadline')}
          />
        </div>
        <br />
        <Notice>
          <p>{t('The deadline will be calculated from the current time')}</p>
        </Notice>
        <br />
        <LoadingButton
          onClick={() => changeDeadline()}
          text={t('Update')}
          isLoading={isUpdatingDeadline}
        />
      </LavendlaModal>
    </div>
  );
}

PaymentStatus.propTypes = {
  stripePayment: PropTypes.object.isRequired,
  isOrderConfirmation: PropTypes.bool
};

export default PaymentStatus;
