import useLoggedInUser from 'ReduxHooks/useLoggedInUser';

function useDateLocale () {
  const { loggedInUserCountry: country } = useLoggedInUser();
  const countryCode = country ? country.code : 'se';

  const getDateLocale = async function () {
    if (countryCode === 'dk') {
      const { sv } = await import('date-fns/locale'); // Fix for DK as date-fns does not format danish dates correctly
      return sv;
    }

    if (countryCode === 'de') {
      const { de } = await import('date-fns/locale');
      return de;
    }

    const { sv } = await import('date-fns/locale');
    return sv;
  };

  return { getDateLocale };
}

export default useDateLocale;
