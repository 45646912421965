import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LocationDropdown from '../LocationDropdown';
import { Toggle, Input, LoadingButton, Textarea, Fieldset, Select } from 'FormElements';
import Appointment from 'Components/Appointments/Appointment';
import { AnimatePresence, motion } from 'framer-motion';
import CompetenceAreaDropdown from '../CompetenceAreaDropdown';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import { TYPE_FUNERAL, TYPE_THERAPY, TYPE_CEREMONY } from 'ReduxActions/competenceAreas';
import { useTranslation } from 'react-i18next';
import useAppointments from 'Hooks/useAppointments';
import Spinner from 'Components/Spinner';
import UsersList from 'Components/OrderCreator/UsersList';
import { AdminOnly, If } from 'Conditionals';
import useInvitations from 'ReduxHooks/useInvitations';
import UserCountryDropdown from 'Components/UserCountryDropdown';
import SiteDropdown from 'Components/SiteDropdown';

function OrderCreationForm ({
  onSubmit,
  isLoading = false,
  isOpen = false,
  isLawyerOrder = false,
  isTombstoneOrder = false,
  isTherapyOrder = false,
  isCeremonyOrder = false
}) {
  const { t } = useTranslation();
  const { loggedInUser, isAdmin } = useLoggedInUser();
  const { users, resetUsers, addCustomerMeeting, getPreCreationUsers } = useInvitations();
  const { getMeetingTypesForSelect, parseMeetingDetailsForSchedule } = useAppointments();
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [country, setCountry] = useState(loggedInUser.country);
  const [data, setData] = useState({
    customInfo: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    appointment: {},
    meetingType: '',
    competenceAreas: [],
    location: null,
    countryId: country ? country.id : null,
    isTest: false,
    user: null,
    siteId: null
  });

  useEffect(() => {
    getUsers();
  }, [data.location, data.appointment, data.competenceAreas]);

  useEffect(() => {
    if (!isOpen) {
      resetUsers();
    }
  }, [isOpen]);

  const motionProps = {
    initial: { opacity: 0, y: 25 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 25 }
  };

  async function getUsers () {
    const {
      location,
      competenceAreas,
      appointment,
      meetingType
    } = data;

    if (!location) {
      return;
    }

    const customerMeeting = {
      ...appointment,
      ...{
        title: meetingType,
        type: 'customer_meeting'
      }
    };

    setIsLoadingUsers(true);

    await addCustomerMeeting(parseMeetingDetailsForSchedule(customerMeeting));
    await getPreCreationUsers({
      competence_areas: competenceAreas.map(({ id }) => id),
      order_type: getOrderType(),
      appointment: customerMeeting,
      country_id: (country) ? country.id : 1,
      location_id: (location) ? location.value : null
    });

    setIsLoadingUsers(false);
  }

  function isSubmissionDisabled () {
    const mandatoryFields = (isAdmin)
      ? [
          'countryId',
          'location'
        ]
      : [
          'countryId',
          'firstName',
          'lastName',
          'location',
          'phone'
        ];

    if (isLoading) {
      return true;
    }

    if (isLawyerOrder && !isAdmin) {
      mandatoryFields.push('customInfo');
    }

    if ((isLawyerOrder || isTherapyOrder) && !data.competenceAreas.length) {
      return true;
    }

    for (const field of mandatoryFields) {
      if (!data[field]) {
        return true;
      }
    }

    return false;
  }

  const updateAppointment = (appointment) => {
    updateFormField('appointment', {
      ...data.appointment,
      ...appointment
    });
  };

  const updateCountry = (countries) => {
    if (!countries.length) {
      return null;
    }

    setCountry(countries[0]);
    updateFormField('countryId', countries[0].id);
  };

  const updateFormField = (key, value = '') => {
    setData({ ...data, ...{ [key]: value } });
  };

  const submit = async (notAssigned = false) => {
    const {
      customInfo,
      firstName,
      lastName,
      email,
      phone,
      appointment,
      location,
      meetingType,
      isTest,
      countryId,
      competenceAreas,
      user,
      siteId
    } = data;

    const orderData = {
      custom_info: customInfo,
      is_test: isTest,
      city: location.label,
      country_id: countryId,
      site_id: siteId,
      contact_counselor_email: (user) ? user.email : '',
      not_assigned: !!notAssigned || null,
      customer: {
        first_name: firstName,
        last_name: lastName,
        email,
        phone
      },
      appointment: isTherapyOrder
        ? appointment
        : {
            ...appointment,
            ...{
              title: meetingType,
              supplier_id: (appointment.supplier) ? appointment.supplier.id : null
            }
          }
    };

    if (!isTombstoneOrder) {
      orderData.competence_areas = competenceAreas;
    }

    await resetUsers();
    await updateFormField('user', null);

    onSubmit(orderData);
  };

  const submitNoQueue = () => submit(false);
  const submitAndQueue = () => submit(true);

  function getCompetenceAreaDropdownProps () {
    switch (true) {
      case isLawyerOrder:
        return {}; // default values
      case isTherapyOrder:
        return {
          type: TYPE_THERAPY
        };
      case isCeremonyOrder:
        return {
          type: TYPE_CEREMONY
        };
      default:
        return {
          type: TYPE_FUNERAL,
          isMulti: true
        };
    }
  }

  function getOrderType () {
    switch (true) {
      case isLawyerOrder:
        return 'App\\LawyerOrder';
      case isTombstoneOrder:
        return 'App\\TombstoneOrder';
      case isTherapyOrder:
        return 'App\\TherapyOrder';
      case isCeremonyOrder:
        return 'App\\CeremonyOrder';
      default:
        return 'App\\Order';
    }
  }

  const requiredIcon = (isAdmin) ? '' : '*';

  return (
    <form
      onSubmit={submit}
      autoComplete='off'
      className='order-creation-form'
      aria-disabled={isSubmissionDisabled()}
    >
      <div className='creator-form-row'>
        <div className='creator-form-row__column half app'>
          <div className='react-select__floating-label'>
            <UserCountryDropdown
              id='country-dropdown__creation'
              selected={data.countryId}
              onChange={updateCountry}
            />
            <label htmlFor='country-dropdown__creation'>{t('Country')} *</label>
          </div>
        </div>

        <If condition={data.countryId === 7}>
          <div className="creator-form-row__column half app">
            <div className="react-select__floating-label">
              <SiteDropdown
                id='site-dropdown__creation'
                selected={data.siteId}
                onChange={site => updateFormField('siteId', site.value)}
                country={country}
                />
              <label htmlFor="site-dropdown__creation">{t('Site')} *</label>
            </div>
          </div>
        </If>

        <div className="creator-form-row__column half app">
          <div className="react-select__floating-label">
            <LocationDropdown
              id="location-dropdown__creation"
              countryId={data.countryId}
              value={data.location ? data.location.value : null}
              onChange={location => updateFormField('location', location)}
            />
            <label htmlFor='location-dropdown__creation'>{t('City')} *</label>
          </div>
        </div>
        {!isTombstoneOrder && (
          <div className='creator-form-row__column half app'>
            <div className='react-select__floating-label'>
              <CompetenceAreaDropdown
                countryId={country.id}
                id='competence-area-dropdown'
                placeholder={t('-- Select --')}
                onChange={competenceAreas => updateFormField('competenceAreas', competenceAreas)}
                {...getCompetenceAreaDropdownProps()}
              />
              <label htmlFor='competence-area-dropdown'>
                {isLawyerOrder ? t('Select type case type *') : t('Select type of order')}
              </label>
            </div>
          </div>
        )}
        {!isTherapyOrder && (
          <div className='creator-form-row__column half'>
            <Select
              floatingLabel
              label={t('Meeting type')}
              id='meeting-type'
              defaultOption={t('-- Select meeting type --')}
              value={data.meetingType}
              options={getMeetingTypesForSelect()}
              onChange={value => updateFormField('meetingType', value)}
            />
          </div>
        )}
      </div>
      {!isTherapyOrder && data.meetingType && (
        <AnimatePresence>
          <motion.div {...motionProps} className='appointment-row'>
            <div className='order-appointments'>
              <h3>{t('Customer meeting')}</h3>
              <Appointment
                showSuppliers={data.meetingType === 'meeting_at_our_offices'}
                onChange={updateAppointment}
                locationId={data.location ? parseInt(data.location.value) : null}
                hideMeetingType
              />
            </div>
          </motion.div>
        </AnimatePresence>
      )}
      <Fieldset legend={t('Customer information')}>
        <div className='creator-form-row'>
          <div className='creator-form-row__column'>
            <Input
              floatingLabel
              id='firstName'
              label={`${t('First name')}${requiredIcon}`}
              value={data.firstName}
              onChange={value => updateFormField('firstName', value)}
            />
          </div>
          <div className='creator-form-row__column'>
            <Input
              floatingLabel
              id='lastName'
              label={`${t('Last name')}${requiredIcon}`}
              value={data.lastName}
              onChange={value => updateFormField('lastName', value)}
            />
          </div>
          <div className='creator-form-row__column'>
            <Input
              floatingLabel
              id='phone'
              label={`${t('Phone')}${requiredIcon}`}
              type='tel'
              value={data.phone}
              onChange={value => updateFormField('phone', value)}
            />
          </div>
          <div className='creator-form-row__column'>
            <Input
              floatingLabel
              id='email'
              label={`${t('Email')}${requiredIcon}`}
              type='email'
              value={data.email}
              onChange={value => updateFormField('email', value)}
            />
          </div>
        </div>
      </Fieldset>
      <div className='creator-form-row'>
        <div className='creator-form-row__column half'>
          <Textarea
            floatingLabel
            id='custom-info'
            label={isLawyerOrder ? `${t('Comment')}${requiredIcon}` : t('Comment')}
            value={data.customInfo}
            rows={6}
            onChange={value => updateFormField('customInfo', value)}
          />
        </div>
      </div>
      <div className='creator-form-row'>
        <div className='creator-form-row__column half'>
          <Toggle
            label={t('This is a test order')}
            defaultChecked={data.isTest}
            onChange={() => updateFormField('isTest', !data.isTest)}
          />
          <small>
            <em>{t('Limitied functionailty. Excluded from statistics.')}</em>
          </small>
          <br />
          <br />
          <LoadingButton
            isLoading={isLoading}
            disabled={isSubmissionDisabled()}
            onClick={submitNoQueue}
            data-cy='submit-create-order-button'
            text={t('Create order')}
          />
          <AdminOnly>
            <LoadingButton
              className='text'
              isLoading={isLoading}
              disabled={isSubmissionDisabled()}
              onClick={submitAndQueue}
              text={t('Create order and create queue')}
            />
          </AdminOnly>
        </div>
      </div>

      <AdminOnly>
        <If condition={isLoadingUsers}>
          <Spinner />
        </If>
        <If condition={!isLoadingUsers}>
          <UsersList
            users={users}
            country={country}
            setUser={(user) => updateFormField('user', user)}
          />
        </If>
      </AdminOnly>
    </form>
  );
}

OrderCreationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  isLawyerOrder: PropTypes.bool,
  isTombstoneOrder: PropTypes.bool,
  isTherapyOrder: PropTypes.bool,
  isCeremonyOrder: PropTypes.bool
};

export default OrderCreationForm;
