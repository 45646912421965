import React from 'react';

const ErrorBoundaryFallbackRender = ({ error }) => {
  return (
    <div className='error-boundary-page-wrapper'>
      <div className='error-boundary-notice'>
        <div className='error-boundary-notice__container'>
          <div className='error-boundary-notice__content'>
            <h4 className='error-boundary-notice__heading'>
              Something went wrong
            </h4>
            <p>Take a screenshot or copy the content of this page to use as reference when reporting this issue.</p>
            <div className='error-boundary-notice__error'>
              <pre>{error.message}</pre>
              <pre>{error.stack}</pre>
              <pre>{window.location.href}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorBoundaryFallbackRender;
