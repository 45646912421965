import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { HeaderWithFilters, Table } from 'Layout';
import { Toggle } from 'FormElements';
import OrderCreator from 'Components/OrderCreator';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import UserDropdown from 'Components/UserDropdown';
import useOrderArchives from '../hooks/useOrderArchives';
import OrderTableRow from 'Components/OrderArchives/OrderTableRow';
import ListingPagination from 'Components/ListingPagination';
import StatusSelect from 'Components/StatusSelect';
import { getOrderArchiveColumns, getBasePath } from 'Library/OrderHelperFuntions';
import useOrderArchivesHistory from '../hooks/useOrderArchivesHistory';
import LocationDropdown from 'Components/LocationDropdown';
import TagsDropdown from 'Components/TagsDropdown';
import { useTranslation } from 'react-i18next';
import { If } from 'Conditionals';
import CompetenceAreaDropdown from 'Components/CompetenceAreaDropdown';
import { TYPE_LEGAL, TYPE_THERAPY } from 'ReduxActions/competenceAreas';
import UserCountryDropdown from 'Components/UserCountryDropdown';

function OrderArchive ({
  history,
  available = false,
  archives = false,
  deleted = false,
  isSearch = false,
  isLawyerOrder = false,
  isTombstoneOrder = false,
  isTherapyOrder = false,
  isCeremonyOrder = false,
  isFuneralOrder = false
}) {
  const {
    orders,
    isLoading,
    pagination,
    getOrders,
    getPercentageCompleteForOrder
  } = useOrderArchives({ isLawyerOrder, isTombstoneOrder, isTherapyOrder, isCeremonyOrder, available, archives, deleted });
  const { t } = useTranslation();
  const { hasMultipleCountries, loggedInUser, toggleFavoritesAtTop, isAdmin } = useLoggedInUser();
  const { getHistoryParams, updateHistory } = useOrderArchivesHistory({ history, isLawyerOrder, isTombstoneOrder, isTherapyOrder, isCeremonyOrder, isFuneralOrder });
  const [favoritesAtTop, setFavoritesAtTop] = useState(loggedInUser.favorites_at_top);
  const params = getHistoryParams();
  const countryId = params?.countryIds.length ? Number(params.countryIds[0]) : null;

  function shouldFetchOrders ({ pathname, search }) {
    const basePath = getBasePath({ isLawyerOrder, isTombstoneOrder, isTherapyOrder, isCeremonyOrder });

    if (search.includes('search')) {
      return true;
    }

    if (pathname === basePath && available) {
      return true;
    }

    if (pathname === `${basePath}/pagaende` && !available && !archives) {
      return true;
    }

    if (pathname === `${basePath}/arkiv` && archives) {
      return true;
    }

    if (pathname === `${basePath}/raderade` && deleted) {
      return true;
    }

    return false;
  }

  useEffect(() => {
    doQuery();
  }, []);

  useEffect(() => {
    return history.listen(location => {
      if (shouldFetchOrders(location)) {
        doQuery();
      }
    });
  }, [history, available]);

  function doQuery () {
    getOrders(getHistoryParams());
  }

  function getHeader () {
    if (available) {
      return `${t('New')} ${getArchiveType()}`;
    }

    if (isSearch) {
      return t('Search results');
    }

    if (deleted) {
      return `${t('Deleted')} ${getArchiveType()}`;
    }

    return (archives) ? `${t('Archived')} ${getArchiveType()}` : `${t('In progress')} ${getArchiveType()}`;
  }

  function getArchiveType () {
    switch (true) {
      case isLawyerOrder:
        return t('Law orders');
      case isTombstoneOrder:
        return t('Tombstone orders');
      case isTherapyOrder:
        return t('Therapy orders');
      case isCeremonyOrder:
        return t('Ceremony orders');
      default:
        return t('Funeral orders');
    }
  }

  function showFilters () {
    return isAdmin && !available && !isSearch;
  }

  const switchPage = direction => {
    let page = null;

    if (direction === 'previous') {
      if (params.page > 1) {
        page = parseInt(params.page) - 1;
      }
    }

    if (direction === 'next') {
      page = parseInt(params.page) + 1;
    }

    if (page) {
      updateHistory({ page });
    }
  };

  return (
    <div className='registry'>
      <HeaderWithFilters header={getHeader()}>
        <div className='header-with-filters__filters__filter'>
          <OrderCreator
            isLawyerOrder={isLawyerOrder}
            isTombstoneOrder={isTombstoneOrder}
            isTherapyOrder={isTherapyOrder}
            isCeremonyOrder={isCeremonyOrder}
          />
        </div>
        {hasMultipleCountries && (
          <div className='header-with-filters__filters__filter'>
            <UserCountryDropdown
              isClearable
              selected={params.countryIds}
              onChange={countries => updateHistory({
                page: 1,
                countryIds: countries ? countries.map(({ id }) => id) : []
              })}
            />
          </div>
        )}
        <If condition={showFilters()}>
          <div className='header-with-filters__filters__filter'>
            <UserDropdown
              user={params.userId}
              countryId={countryId}
              lawyers={isLawyerOrder}
              tombstoneUsers={isTombstoneOrder}
              therapists={isTherapyOrder}
              officiantUsers={isCeremonyOrder}
              placeholder={isLawyerOrder ? t('Choose lawyer') : t('Choose advisor')}
              onChange={user => updateHistory({
                page: 1,
                userId: user || -1
              })}
            />
          </div>

          <If condition={isLawyerOrder || isTherapyOrder}>
            <div className='header-with-filters__filters__filter'>
              <CompetenceAreaDropdown
                isMulti
                type={isLawyerOrder ? TYPE_LEGAL : TYPE_THERAPY}
                countryId={countryId}
                selected={params.competenceAreas}
                placeholder={isLawyerOrder || isTherapyOrder ? t('Select case type') : t('Select type of order')}
                onChange={competence => updateHistory({
                  page: 1,
                  competenceAreas: competence ? competence.map(({ id }) => id) : []
                })}
              />
            </div>
          </If>
        </If>
        <If condition={!archives && !available && !isSearch && !deleted}>
          <div className='header-with-filters__filters__filter'>
            <StatusSelect
              defaultValue={params.status}
              onChange={status => updateHistory({
                page: 1,
                status
              })}
            />
          </div>
        </If>

        <div className='header-with-filters__filters__filter'>
          <TagsDropdown
            id='tags-dropdown'
            isMulti
            countryId={countryId}
            placeholder={t('Select tags')}
            value={params.tagIds}
            isLawyerOrder={isLawyerOrder}
            isTombstoneOrder={isTombstoneOrder}
            isTherapyOrder={isTherapyOrder}
            isCeremonyOrder={isCeremonyOrder}
            onChange={tags => updateHistory({
              page: 1,
              tagIds: tags ? tags.map(({ value }) => value) : []
            })}
          />
        </div>

        <If condition={!isSearch}>
          <div className='header-with-filters__filters__filter'>
            <LocationDropdown
              isMulti
              countryId={countryId}
              id='location-dropdown'
              placeholder={t('Select locations')}
              value={params.locationIds}
              onChange={locations => updateHistory({
                page: 1,
                locationIds: locations ? locations.map(({ value }) => value) : []
              })}
            />
          </div>
        </If>
      </HeaderWithFilters>

      <div className='filtering__wrapper'>
        <Toggle
          small
          label={t('Show favorites at the top')}
          isDisabled={isLoading}
          defaultChecked={favoritesAtTop}
          onChange={checked => {
            setFavoritesAtTop(checked);
            toggleFavoritesAtTop();
            updateHistory({ favorite: checked });
          }}
        />

        <If condition={(isFuneralOrder || isLawyerOrder || isTombstoneOrder) && !available}>
          <Toggle
            small
            label={t('Show orders with unpaid invoices')}
            isDisabled={isLoading}
            defaultChecked={params.unpaid === 'true'}
            onChange={checked => {
              const unpaid = checked;
              updateHistory({ unpaid });
            }}
          />
        </If>
      </div>

      {(!orders.length && !isLoading)
        ? (
          <p className='no-orders__message'>{t('No orders found')}</p>
          )
        : (
          <Table
            isLoading={isLoading}
            className='order-archive'
            thead={getOrderArchiveColumns({
              isAdmin,
              isLawyerOrder,
              isFuneralOrder,
              isTombstoneOrder,
              isTherapyOrder,
              isCeremonyOrder,
              hasMultipleCountries
            })}
          >
            {orders.map(order => {
              const props = {
                order,
                available,
                archives,
                deleted,
                history,
                isFuneralOrder,
                isTombstoneOrder,
                isLawyerOrder,
                isTherapyOrder,
                isCeremonyOrder,
                isAdmin,
                percentageComplete: getPercentageCompleteForOrder(order)
              };

              return (
                <OrderTableRow
                  key={order.id}
                  {...props}
                />
              );
            })}
          </Table>
          )}

      <ListingPagination
        pagination={pagination}
        switchPage={switchPage}
      />
    </div>
  );
}

OrderArchive.propTypes = {
  history: PropTypes.object.isRequired,
  available: PropTypes.bool,
  archives: PropTypes.bool,
  deleted: PropTypes.bool,
  isSearch: PropTypes.bool,
  isLawyerOrder: PropTypes.bool,
  isTombstoneOrder: PropTypes.bool,
  isTherapyOrder: PropTypes.bool,
  isCeremonyOrder: PropTypes.bool,
  isFuneralOrder: PropTypes.bool
};

export default OrderArchive;
