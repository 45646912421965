import API from '../../stores/API';

export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_COUNTRY = 'SET_COUNTRY';
export const SET_COUNTRY_SETTINGS = 'SET_COUNTRY_SETTINGS';

function recieveCountries (payload) {
  return {
    type: SET_COUNTRIES,
    payload
  };
}

function recieveCountry (payload) {
  return {
    type: SET_COUNTRY,
    payload
  };
}

function recieveCountrySettings (payload) {
  return {
    type: SET_COUNTRY_SETTINGS,
    payload
  };
}

export function getCountries () {
  return async (dispatch, getState) => {
    const { countries } = getState();

    if (countries.countries.length) {
      return;
    }

    const { data } = await API.GET('/countries');
    return dispatch(recieveCountries(data));
  };
}

export function getCountrySettings (id) {
  return async (dispatch, getState) => {
    const { countries: { country } } = getState();

    if (!id) {
      id = country.id;
    }

    const { data } = await API.GET(`/country-settings/${id}`);

    return dispatch(recieveCountrySettings(data.country_settings));
  };
}

export function setCountry (country) {
  return async (dispatch) => {
    await dispatch(recieveCountry(country));
  };
}

export function setCountrySettings (countrySettings) {
  return async (dispatch) => {
    await dispatch(recieveCountrySettings(countrySettings));
  };
}

export function saveCountrySettings () {
  return async (dispatch, getState) => {
    const { countries: { countrySettings } } = getState();
    const { data } = await API.PUT(`/country-settings/${countrySettings.country_id}`, countrySettings);

    return dispatch(recieveCountrySettings(data));
  };
}
